import React, { useState, useEffect, useCallback } from 'react'
import Link from 'next/link'
import { DateTime } from 'luxon'
import { IProposal, ProposalStatus } from '@/models/IProposal'
import useApi from '@/hooks/useApi'
import { Flex, Text, Icon } from '@/components/ui'
import { showToast } from '@/utils/toast'
import {
  DashedDivider,
  DesktopOnlyContainer,
  Divider,
  FullScreenMobileContainer,
  FullScreenPopupContentWrapper,
  MobileOnlyContainer,
} from '@/components/uiJar/utilityStyles'
import { PopupTopBar } from '@/components/uiJar/popup'
import { helper } from '@/utils/helper'
import { Button } from '@/components/uiJar/Button'
import { IUserVisaWizardPayment } from '@/models/IUserVisaWizardPayment'
import { useWizardContext } from '@/contexts/Wizard'
import getAdvisorTitle from '@/utils/getAdvisorTitle'
import { Checkbox } from '@/components/uiJar/checkbox'
import {
  ProposalNotice,
  DuePayment,
  AgreementBox,
  PopupFooter,
  ProposalTimeBox,
} from './styles'

export type ViewProposalDetailProps = {
  onClose: () => void
  onGoBack: () => void
  proposal?: IProposal | null
  hasActions?: boolean
  platformFee?: number
  onPayment: (data: IUserVisaWizardPayment) => void
  mode?: 'user' | 'advisor'
  acceptedDate?: string | null
}

export const ViewProposalDetail: React.FC<ViewProposalDetailProps> = ({
  onClose,
  platformFee = 30,
  proposal,
  onPayment,
  onGoBack,
}) => {
  const { mode, userWizard } = useWizardContext()
  const { api, status } = useApi()
  const [advisorProposal] = useState<IProposal | null | undefined>(proposal)
  const [acceptedTerms, toggleAcceptedTerms] = useState(false)
  const [acceptedAdvisorTerms, toggleAcceptedAdvisorTerms] = useState(false)
  const [proposalTotal, setProposalTotal] = useState<string>()
  const [dueToday, setDueToday] = useState<string>('0')

  useEffect(() => {
    const prices = advisorProposal?.prices
    const total =
      prices?.map((item) => item.price).reduce((a, b) => a + b, 0) || 0
    setProposalTotal(Math.ceil(total).toLocaleString())
    setDueToday(advisorProposal?.initialPayment?.toLocaleString() || '0')
  }, [advisorProposal?.prices, advisorProposal?.initialPayment, platformFee])

  const handleUpdate = useCallback(
    (status: ProposalStatus.Accepted | ProposalStatus.Rejected) => {
      advisorProposal &&
        api.wizard
          .updateProposal(advisorProposal?.id, {
            status,
            initialPayment: advisorProposal?.initialPayment || null,
          })
          .then((response) => {
            showToast({
              type: 'success',
              message: `Proposal ${
                status === ProposalStatus.Accepted ? 'accepted' : 'rejected'
              }`,
            })

            if (status === ProposalStatus.Accepted && response.initialPayment) {
              onPayment(response.initialPayment)
            } else {
              onClose()
            }
          })
    },
    [advisorProposal, api.wizard, onClose, onPayment],
  )

  const unResolvedProposal =
    proposal?.status === ProposalStatus.Sent ||
    proposal?.status === ProposalStatus.Updated

  const proposalSentDate = DateTime.fromISO(proposal?.createdAt || '')
  const proposalAcceptedDate = DateTime.fromISO(proposal?.updatedAt || '')

  return (
    <FullScreenMobileContainer>
      <PopupTopBar onClose={onClose}>View proposal</PopupTopBar>
      <FullScreenPopupContentWrapper>
        <Flex direction="column" gap={32}>
          {unResolvedProposal ? (
            <ProposalNotice>
              <Icon.Solid name="circlExclamation" size={20} />
              <Text variant="regular-3">
                Please, read carefully and accept{' '}
                <strong>Terms of Services</strong> before accepting the proposal
              </Text>
            </ProposalNotice>
          ) : (
            <Flex direction="column" gap={2}>
              <ProposalTimeBox>
                <Text variant="regular-4">Proposal sent date</Text>
                <Text variant="medium-4">
                  {proposalSentDate.toFormat("d'th' MMMM, ha 'UTC'")}
                </Text>
              </ProposalTimeBox>
              <ProposalTimeBox>
                <Text variant="regular-4">Proposal accept date</Text>
                <Text variant="medium-4">
                  {proposal?.status === ProposalStatus.Accepted
                    ? proposalAcceptedDate.toFormat("d'th' MMMM, ha 'UTC'")
                    : '--'}
                </Text>
              </ProposalTimeBox>
            </Flex>
          )}
          <Flex direction="column" gap={8}>
            <Flex direction="column" gap={20}>
              <Flex justifyContent="space-between">
                <Text variant="medium-3" colorVar="neutral-11">
                  Service
                </Text>
                <Text variant="medium-3" colorVar="neutral-11">
                  Price
                </Text>
              </Flex>
              {advisorProposal?.prices?.map((item, index) => (
                <Flex key={index} justifyContent="space-between">
                  <Text variant="regular-4">{item.title}</Text>
                  <Text variant="regular-4">
                    ${item.price.toLocaleString()}
                  </Text>
                </Flex>
              ))}
              <Divider />
              <Flex justifyContent="space-between">
                <Text variant="semibold-4">Proposed Total</Text>
                <Text variant="semibold-4">${proposalTotal}</Text>
              </Flex>
              {unResolvedProposal && (
                <DuePayment>
                  <Text variant="semibold-4">{'Due Today'}</Text>
                  <Text variant="semibold-4">${dueToday}</Text>
                </DuePayment>
              )}
            </Flex>
          </Flex>
          <DashedDivider />
          <Flex direction="column" gap={8}>
            <Text variant="medium-3" colorVar="neutral-11">
              {mode === 'advisor'
                ? 'Summary'
                : `${getAdvisorTitle(userWizard.country?.isoCode)}’s Summary`}
            </Text>
            {helper
              .getParagraphs(advisorProposal?.summary || '')
              .map((paragraph, index) => (
                <Text key={`Para-${index}`} variant="regular-4">
                  {paragraph}
                </Text>
              ))}
          </Flex>
          {unResolvedProposal && (
            <AgreementBox>
              <Checkbox
                size={2}
                checked={acceptedTerms}
                onChange={(e) => toggleAcceptedTerms(e.target.checked)}
                className="checkbox"
              >
                <Text variant="regular-3" colorVar="neutral-11">
                  By accepting this proposal you agree with our{' '}
                  <Link
                    href="https://citizenremote.com/terms-of-use/"
                    target="_blank"
                  >
                    Terms of Services
                  </Link>{' '}
                  and{' '}
                  <Link
                    href="https://citizenremote.com/privacy-policy/"
                    target="_blank"
                  >
                    Privacy Policy
                  </Link>
                </Text>
              </Checkbox>
              <Checkbox
                size={2}
                checked={acceptedAdvisorTerms}
                onChange={(e) => toggleAcceptedAdvisorTerms(e.target.checked)}
                className="checkbox"
              >
                <Text variant="regular-3" colorVar="neutral-11">
                  {`By accepting this proposal you agree with ${getAdvisorTitle(
                    userWizard.country?.isoCode,
                  )}’s Summary, Services, and Prices as outlined in this proposal above`}
                </Text>
              </Checkbox>
            </AgreementBox>
          )}
        </Flex>
        <MobileOnlyContainer>
          {unResolvedProposal ? (
            <PopupFooter>
              <Button
                fullWidth
                variant="outline"
                colorTheme="neutral"
                size={3}
                onClick={() => handleUpdate(ProposalStatus.Rejected)}
              >
                Reject Proposal
              </Button>
              <Button
                fullWidth
                variant="solid"
                colorTheme="neutral"
                size={3}
                disabled={!acceptedTerms || !acceptedAdvisorTerms}
                onClick={() => handleUpdate(ProposalStatus.Accepted)}
                isLoading={status === 'posting'}
              >
                {!!advisorProposal?.initialPayment
                  ? 'Accept and Pay'
                  : 'Accept Proposal'}
              </Button>
            </PopupFooter>
          ) : (
            <PopupFooter>
              <Button
                onClick={onGoBack}
                fullWidth
                variant="outline"
                size={3}
                colorTheme="neutral"
              >
                Back
              </Button>
              <Button
                onClick={onClose}
                fullWidth
                variant="solid"
                size={3}
                colorTheme="neutral"
              >
                Close
              </Button>
            </PopupFooter>
          )}
        </MobileOnlyContainer>
      </FullScreenPopupContentWrapper>
      <DesktopOnlyContainer>
        {unResolvedProposal ? (
          <PopupFooter>
            <Button
              fullWidth
              variant="outline"
              colorTheme="neutral"
              size={3}
              onClick={() => handleUpdate(ProposalStatus.Rejected)}
            >
              Reject Proposal
            </Button>
            <Button
              fullWidth
              variant="solid"
              colorTheme="neutral"
              size={3}
              disabled={!acceptedTerms || !acceptedAdvisorTerms}
              onClick={() => handleUpdate(ProposalStatus.Accepted)}
              isLoading={status === 'posting'}
            >
              {!!advisorProposal?.initialPayment
                ? 'Accept and Pay'
                : 'Accept Proposal'}
            </Button>
          </PopupFooter>
        ) : (
          <PopupFooter>
            <Button
              onClick={onGoBack}
              fullWidth
              variant="outline"
              size={3}
              colorTheme="neutral"
            >
              Back
            </Button>
            <Button
              onClick={onClose}
              fullWidth
              variant="solid"
              size={3}
              colorTheme="neutral"
            >
              Close
            </Button>
          </PopupFooter>
        )}
      </DesktopOnlyContainer>
    </FullScreenMobileContainer>
  )
}
