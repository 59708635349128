import { CalProvider } from '@calcom/atoms'
import { BookingResponse } from '@calcom/atoms/dist/packages/features/bookings/types'
import { DateTime } from 'luxon'
import { useRouter } from 'next/router'
import {
  Dispatch,
  forwardRef,
  SetStateAction,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { Flex, Icon, PaymentPopup, Text } from '@/components/ui'
import { Button } from '@/components/uiJar/Button'
import { Popup } from '@/components/uiJar/popup'
import {
  DefaultPopupContainer,
  DefaultPopupContentWrapper,
  DefaultPopupFooter,
} from '@/components/uiJar/utilityStyles'
import { PaymentReason } from '@/constants/payments'
import useApi from '@/hooks/useApi'
import { IAdvisor } from '@/models/IAdvisor'
import { ICalBooking } from '@/models/ICalBooking'
import { IUser } from '@/models/IUser'
import { CalComBookingIntent } from '@/utils/Api/payments.service'
import { helper } from '@/utils/helper'
import { AdvisorEventType } from '@/models/IAdvisorEvent'
import { useAuthContext } from '@/contexts/Auth'
import BookerPopup from '@/components/scheduling/booker-popup'
import updateMissingNameFromBooking from '@/utils/updateMissingNameFromBooking'

export const CalBookingFlow = forwardRef<
  { trigger: () => void },
  {
    calAccessToken?: string
    advisor: IUser
    advisorProfile: IAdvisor | null
    user: IUser
    userWizardId: string
    meeting: ICalBooking | null
    setBookedMeeting: Dispatch<SetStateAction<ICalBooking | null>>
    eventType?: AdvisorEventType
    eventSlug?: string
    setEventSlug?: Dispatch<SetStateAction<string | undefined> | undefined>
    setEventType?: Dispatch<
      SetStateAction<AdvisorEventType | undefined> | undefined
    >
  }
>(
  (
    {
      advisor,
      user,
      calAccessToken,
      userWizardId,
      meeting,
      setBookedMeeting,
      advisorProfile,
      eventType,
      eventSlug,
      setEventSlug,
      setEventType,
    },
    ref,
  ) => {
    const { api } = useApi()
    const router = useRouter()
    const [isTriggered, setTriggered] = useState(false)
    const [step, setStep] = useState(0)
    const [bookingUid, setBookingUid] = useState<string>('')
    const [formattedBookedDate, setFormattedBookedDate] = useState<string>('')
    const { user: currentUser } = useAuthContext()
    const handleClose = () => {
      setEventType && setEventType(undefined)
      setEventSlug && setEventSlug(undefined)
      setTriggered(false)
      setStep(0)
    }
    const getBookingDetails = useCallback(
      async (bookingUid: string, eventType?: AdvisorEventType) => {
        api.wizard.getBookingDetails(bookingUid).then((response) => {
          if (response) {
            setBookedMeeting(response)
            //Update context meeting object
            const dateTime = DateTime.fromISO(
              response?.dateFrom as string,
            ).setZone('local')
            const day = dateTime.day
            const ordinalSuffix = helper.getOrdinalSuffix(day)
            const formattedDate = dateTime.toFormat(
              `MMMM d'${ordinalSuffix}', yyyy 'and' h:mm 'local time'`,
            )
            setFormattedBookedDate(formattedDate)
            if (eventType == 'free') {
              setStep(2)
            }
          }
        })
      },
      [api.wizard, setBookedMeeting],
    )
    useEffect(() => {
      const bookingUid = router.query.bookingUid as string
      const reschedule = router.query.reschedule as string
      if (bookingUid) {
        setTriggered(true)
        if (reschedule === 'true') setStep(2)
        else setStep(1)

        getBookingDetails(bookingUid)
      }
    }, [
      router.query.bookingUid,
      getBookingDetails,
      router.query.reschedule,
      api,
    ])

    useImperativeHandle(
      ref,
      () => {
        return {
          trigger: () => setTriggered(true),
        }
      },
      [],
    )

    const returnUrl = `${
      new URL(location.pathname, location.href).href
    }?bookingUid=${bookingUid}`

    if (!isTriggered) return null
    if (step === 0 && (!calAccessToken || !userWizardId)) return null
    const calEventSlug = eventSlug || advisorProfile?.paidMeeting?.slug
    const isReschedule = router.query.reschedule
    return (
      <>
        <CalProvider
          language="en"
          accessToken={calAccessToken}
          clientId={process.env.NEXT_PUBLIC_CALCOM_OAUTH_CLIENT_ID ?? ''}
          options={{
            apiUrl: process.env.NEXT_PUBLIC_CALCOM_API_URL ?? '',
            refreshUrl: `${process.env.NEXT_PUBLIC_API_BASE}/cal-com/refresh/${advisor?.id}`,
          }}
          autoUpdateTimezone={true}
        >
          <BookerPopup
            mode={
              currentUser && currentUser.role == 'Lawyer' ? 'advisor' : 'client'
            }
            isOpen={step === 0}
            onClose={handleClose}
            bookerProps={{
              username: advisorProfile?.calUserName as string,
              eventSlug: calEventSlug as string,
              onCreateBookingSuccess: async (booking) => {
                updateMissingNameFromBooking(user as IUser, booking, api)
                const data: BookingResponse = booking.data
                if (meeting) {
                  setStep(2)
                  router.push({
                    pathname: router.pathname,
                    query: {
                      ...router.query,
                      bookingUid: meeting.meetingId,
                      reschedule: 'true',
                    },
                  })
                } else {
                  setBookingUid(data?.uid as string)
                  if (eventType && eventType == 'free' && !meeting) {
                    getBookingDetails(data?.uid as string, eventType)
                    return
                  }
                  setStep(1)
                }
              },
              defaultFormValues: {
                email: user?.email || ' ',
                name: helper.formatName(user?.firstName, user?.lastName) || ' ',
              },
              rescheduleUid: meeting ? meeting.meetingId : undefined,
              metadata: {
                userId: user?.id,
                advisorId: advisor?.id || '0',
                wizardId: userWizardId,
              },
            }}
          />
        </CalProvider>

        <Popup fullScreenInMobile isOpen={step === 1}>
          <PaymentPopup<CalComBookingIntent>
            type={PaymentReason.CalComBooking}
            advisorId={advisor?.id as string}
            calComBookingId={bookingUid}
            returnUrl={returnUrl}
            calComEventSlug={calEventSlug as string}
            onSuccess={() => {
              setStep(2)
            }}
            onClose={() => {
              setStep(0)
            }}
          />
        </Popup>
        <Popup isOpen={step === 2}>
          <DefaultPopupContainer>
            <DefaultPopupContentWrapper>
              <Flex direction="column" gap={20}>
                <Icon.Solid name="checkCircle" size={48} />
                <Flex direction="column" gap={4}>
                  <Text variant="semibold-4">Congratulations!</Text>
                  <Text variant="regular-4" colorVar="neutral-11">
                    {isReschedule
                      ? `Your meeting has been rescheduled to ${formattedBookedDate}`
                      : `You successfully booked a call with ${helper.formatAdvisorName(
                          advisor?.firstName,
                          advisor?.lastName,
                        )} on ${formattedBookedDate}`}
                  </Text>
                </Flex>
              </Flex>
            </DefaultPopupContentWrapper>
            <DefaultPopupFooter>
              <Button
                variant="solid"
                size={3}
                fullWidth
                colorTheme="neutral"
                onClick={() => {
                  //clean query params
                  router.push(router.pathname, router.asPath.split('?')[0], {
                    shallow: true,
                  })
                  handleClose()
                }}
              >
                Continue
              </Button>
            </DefaultPopupFooter>
          </DefaultPopupContainer>
        </Popup>
      </>
    )
  },
)

CalBookingFlow.displayName = 'CalBookingFlow'
