import styled, { css } from 'styled-components'
import { Flex, PopupPadding, textVariants } from '@/components/ui'
import {
  DefaultPopupContentWrapper,
  DefaultPopupFooter,
  FullScreenMobileContainer,
  FullScreenPopupContentWrapper,
  thinModalScrollbar,
} from '@/components/uiJar/utilityStyles'
import { Media } from '@/utils/media'

export const PopupContainer = styled(FullScreenMobileContainer)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  height: 100dvh;
  ${Media.Tablet`
    display: block;
    width: 768px;
    height: auto;    
  `}
  ${Media.Laptop`
      display: block;
      width: 882px;
   `}
  .custom-topbar {
    border-bottom: none;
    ${Media.Tablet`
      border-bottom: 1px solid var(--neutral-alpha-4);
      `}
  }
`

export const Tab = styled.div<{ selected?: boolean }>`
  ${textVariants['medium-3']}
  cursor: pointer;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid
    ${(p) => (p.selected ? 'var(--neutral-12)' : 'var(--neutral-alpha-6)')};
  width: 50%;
  color: ${(p) => (p.selected ? 'var(--neutral-12)' : 'var(--neutral-11)')};
  &:hover {
    color: var(--neutral-12);
  }
`

export const PopupGridWrapper = styled(FullScreenPopupContentWrapper)`
  padding: 0;
`

export const PopupGrid = styled(Flex)`
  width: 100%;
  flex-grow: 1;
  overflow: hidden;
`

export const PopupContent = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  flex: 1;
  padding: 20px;
  overflow: auto;
  ${thinModalScrollbar}
`

export const RequestPaymentContainer = styled(Flex)<{
  selectedTab?: boolean
}>`
  width: 100%;
  flex-direction: column;
  display: ${(p) => (p.selectedTab ? 'flex' : 'none')};
  ${Media.Tablet`
    display: flex;
  `}
`

export const PaymentBreakdownContainer = styled(Flex)<{
  selectedTab?: boolean
}>`
  background: var(--neutral-2);
  width: 100%;
  flex-direction: column;
  display: ${(p) => (p.selectedTab ? 'flex' : 'none')};
  ${Media.Tablet`
    display: flex;
  `}
`

export const GreyColumn = styled(Flex)`
  background: var(--neutral-2);
  border-radius: 12px;
  padding: 16px;
  flex-direction: column;
  gap: 16px;
`

export const WhiteColumn = styled(Flex)`
  flex-direction: column;
  flex: 1;
`

export const Label = styled.label`
  display: flex;
  flex-direction: column;
  ${textVariants['body-s-medium']}
  row-gap: 4px;
  margin-bottom: 20px;
`

export const Calculation = styled(Flex)<{ isCenter?: boolean }>`
  flex-direction: column;
  ${(p) => p.isCenter && 'justify-content: center;'}
  flex-grow: 1;
`

export const Footer = styled.footer`
  ${textVariants['body-s-medium']}
  color: var(--grey-500);
`

export const Pricing = styled.div`
  margin-top: 16px;
  margin-bottom: 8px;
  border-radius: 16px;
  background-color: var(--panel-1);

  > div {
    display: flex;
    padding: 16px;
    justify-content: space-between;
    align-items: center;
    color: var(--grey-500);
  }

  > div:last-child {
    border: 1px solid var(--grey-200);
    color: var(--grey-900);
  }
`

export const YourEarning = styled(Flex)`
  padding: 16px;
  border-radius: 16px;
  background: var(--base-gradient);
  color: var(--panel-1);
  justify-content: space-between;
  align-items: center;
`

export const TotalsContainer = styled(Flex)`
  border-radius: 16px;
  gap: 16px;
  background: var(--background-reverse-1);
  padding: 16px;
  .full-width {
    width: 100%;
  }
`

export const Content = styled(DefaultPopupContentWrapper)`
  padding-bottom: 52px;
`

export const Actions = styled(DefaultPopupFooter)`
  flex-direction: row;
  gap: 8px;
`

export const PopupFooter = styled(DefaultPopupFooter)<{ direction?: string }>`
  border-top: transparent;
  flex-shrink: 0;
  gap: 8px;
`

export const DropdownItem = styled.div<{
  selected?: boolean
  disabled?: boolean
}>`
  cursor: pointer;
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 8px;
  border-radius: 8px;
  ${({ selected }) => selected && 'background: var(--neutral-alpha-3);'}
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
  &:hover {
    background: var(--neutral-alpha-3);
  }
  .labels-wrapper {
    display: flex;
    flex-direction: column;
    gap: 2px;
    flex-grow: 1;
  }
`
