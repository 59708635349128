import Apple from './apple.svg'
import Google from './google.svg'
import Logo from './logo.svg'
import LogoFull from './logo_full.svg'
import LogoFullBlack from './logo_full_black.svg'
import DownloadGoogle from './download_google.svg'
import DownloadApple from './download_apple.svg'
import DownloadQR from './download_qr.svg'
import Loading from './loading.svg'
import regionWorld from './regionWorld.svg'
import regionAfrica from './regionAfrica.svg'
import regionAsia from './regionAsia.svg'
import regionAustralia from './regionAustralia.svg'
import regionEurope from './regionEurope.svg'
import regionNAmerica from './regionNAmerica.svg'
import regionSAmerica from './regionSAmerica.svg'
import premiumWand from './premium_wand.svg'
import bgAdviser from './bg-adviser.svg'
import ProfileStar1 from './profile-star1.svg'
import ProfileStar2 from './profile-star2.svg'
import worldTrips from './worldTrips.svg'
import sparkle2 from './sparkle2.svg'
import logoIcon from './logo_icon.svg'
import logoPassport from './logo-passport.svg'
import logoRenewed from './logo-renewed.svg'
import facebook from './facebook.svg'
import instagram from './instagram.svg'
import linkedin from './linkedin.svg'
import twitter from './twitter.svg'
import bubblesMessages from './bubbles-messages.svg'
import celebrateParty from './celebrate-party.svg'
import earthGlobe from './earth-globe.svg'
import emojiSmilingFace from './emoji-smiling-face.svg'
import group2Users from './group-2-users.svg'
import headAvatar from './head-avatar.svg'
import magicWand from './magic-wand.svg'
import notes from './notes.svg'
import robot from './robot.svg'
import avatarSparkle from './avatar-sparkle.svg'
import document from './document.svg'
import folder from './folder.svg'
import folder2 from './folder-2.svg'
import calendarClock from './calendar-clock.svg'
import outlookCalendar from './outlook-calendar.svg'
import loader1 from './loader-1.svg'
import loader2 from './loader-2.svg'
import loader3 from './loader-3.svg'
import loader4 from './loader-4.svg'
import loader5 from './loader-5.svg'
import loader6 from './loader-6.svg'
import loader7 from './loader-7.svg'

export const other = {
  Apple,
  Google,
  Logo,
  LogoFull,
  LogoFullBlack,
  logoPassport,
  DownloadApple,
  DownloadGoogle,
  DownloadQR,
  Loading,
  regionWorld,
  regionAfrica,
  regionAsia,
  regionAustralia,
  regionEurope,
  regionNAmerica,
  regionSAmerica,
  premiumWand,
  bgAdviser,
  ProfileStar1,
  ProfileStar2,
  worldTrips,
  logoIcon,
  logoRenewed,
  sparkle2,
  facebook,
  twitter,
  linkedin,
  instagram,
  bubblesMessages,
  celebrateParty,
  earthGlobe,
  emojiSmilingFace,
  group2Users,
  headAvatar,
  magicWand,
  notes,
  robot,
  avatarSparkle,
  document,
  folder,
  folder2,
  calendarClock,
  outlookCalendar,
  loader1,
  loader2,
  loader3,
  loader4,
  loader5,
  loader6,
  loader7,
}
