import styled from 'styled-components'
import { PopupPadding, Flex, textVariants } from '@/components/ui'
import { Media } from '@/utils/media'
import { DefaultPopupFooter } from '@/components/uiJar/utilityStyles'

export const PopupContent = styled.div<{ isNew: boolean; isViewing?: boolean }>`
  width: ${(p) => (p.isNew ? '512px;' : '416px;')}
  overflow: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  max-height: ${(p) => (p.isViewing ? '600px;' : '500px;')}
  scrollbar-width: none;
  padding: ${PopupPadding};

  hr {
    border-top: 1px solid var(--gery-200);
  }
`

export const GreyColumn = styled(Flex)`
  flex-direction: column;
  flex: 1;
  background-color: var(--grey-100);
`

export const WhiteColumn = styled(Flex)`
  flex-direction: column;
  flex: 1;
`

export const PopupActions = styled(Flex)`
  padding: ${PopupPadding};
  border-top: 0.4px solid var(--grey-300);
`

export const Label = styled.label`
  display: flex;
  flex-direction: column;
  ${textVariants['caption-s-medium']}
  margin-top: 20px;
  gap: 10px;

  textarea {
    resize: none;
  }
`

export const ProposalPrices = styled.div``

export const PricesHeader = styled(Flex)`
  gap: 8px;
`

export const ServiceItemWrapper = styled(Flex)`
  flex-grow: 1;
`

export const PriceItemWrapper = styled(Flex)`
  width: 110px;
  flex-shrink: 0;
`

export const ActionItemWrapper = styled(Flex)<{ $disabled?: boolean }>`
  width: 24px;
  flex-shrink: 0;
  ${(p) => p.$disabled && 'opacity: 0.5; pointer-events: none;'}
`

export const PricesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 8px;
`

export const PriceField = styled(Flex)`
  gap: 8px;
`

export const TotalsContainer = styled(Flex)<{ isViewing?: boolean }>`
  padding: 16px;
  background-color: var(--neutral-2);
  border-radius: 16px;
`

export const SummaryBox = styled(Flex)`
  margin-bottom: 16px;
`

export const BreakdownTitle = styled(Flex)``

export const BreakdownItem = styled(Flex)<{ noBorder?: boolean }>`
  padding: 16px;
  ${(p) => !p.noBorder && 'border-top: 1px solid var(--grey-200)'}
`

export const TermsBox = styled(Flex)`
  padding: ${PopupPadding};

  p {
    a {
      color: var(--purple-900);
    }
  }
`

export const RequestPaymentNotice = styled(Flex)`
  gap: 16px;
  .how-tip {
    flex-shrink: 0;
  }
`

export const ProposalDatesContainer = styled.div`
  padding: ${PopupPadding};
`

export const ProposalDates = styled.div`
  border-radius: 12px;
  border: 1px solid var(--grey-200);
  background: var(--grey-100);
  overflow: hidden;

  div {
    padding: 16px;
  }

  div:first-child {
    border-bottom: 1px solid var(--grey-200);
  }
`

export const Notice = styled.footer`
  ${textVariants['body-s-medium']}
  color: var(--grey-500);
  padding: ${PopupPadding};
`

export const Content = styled.div`
  padding: ${PopupPadding};
  width: 384px;
`

export const ProposalNotice = styled(Flex)`
  ${textVariants['regular-3']}
  border-radius: 12px;
  background-color: var(--accent-3);
  padding: 16px;
  color: var(--accent-12);
  i {
    flex-shrink: 0;
    color: var(--accent-9);
  }
  .strong {
    ${textVariants['semibold-3']}
  }
  gap: 8px;
  ${Media.Tablet`
    padding-right: 54px;
    `}
`

export const DuePayment = styled(Flex)`
  padding: 12px 16px;
  background-color: var(--neutral-alpha-3);
  justify-content: space-between;
  border-radius: 10px;
`
export const AgreementBox = styled(Flex)`
  flex-direction: column;
  gap: 32px;
  a {
    text-decoration: underline;
  }
  .terms-checkbox {
    align-items: flex-start;
  }
`

export const PopupFooter = styled(DefaultPopupFooter)`
  margin-top: 8px;
  border-top: transparent;
  padding: 24px 0px;
  ${Media.Tablet`
    padding: 16px 20px;
    border-top: 1px solid var(--neutral-alpha-4);
    margin-top: 0
  `}
`
export const WarningPopupContent = styled(Flex)`
  flex-direction: column;
  gap: 4px;
  margin-bottom: 32px;
`
