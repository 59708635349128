import React, { InputHTMLAttributes, useState } from 'react'
import styled, { css } from 'styled-components'
import { Icon } from './Icon'
import { textVariants } from './Text'

interface Props
  extends InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
  multiline?: boolean
  rows?: number
  invalid?: boolean
  preIcon?: React.ReactNode
  postIcon?: React.ReactNode
}

const Container = styled.div<Props>`
  position: relative;
  ${(p) =>
    p.preIcon &&
    css`
      input,
      textarea {
        padding-left: 50px;
      }
    `}

  ${(p) =>
    p.postIcon &&
    css`
      input,
      textarea {
        padding-right: 50px;
      }
    `}
`

const InputBase = css<Props>`
  ${textVariants['body-m-regular']}
  font-family: inherit;
  padding: 14px;
  border: none;
  outline: none;
  border-radius: 12px;
  transition: all 0.1s;
  background-color: var(--grey-100);

  ${(p) =>
    !p.invalid &&
    css`
      &:hover {
        background-color: var(--grey-200);
      }

      &:focus {
        background-color: var(--panel-1);
        outline: 1.5px solid var(--purple-500);
      }
    `}

  ${(p) =>
    p.invalid &&
    css`
      background-color: var(--rose-25);
      outline: 1.5px solid var(--rose-500);
    `}

  ${(p) =>
    p.disabled &&
    css`
      background-color: var(--grey-100);
      pointer-events: none;
    `}

  &::placeholder {
    color: var(--grey-500);
  }
`

const Field = styled.input`
  ${InputBase}
  width: 100%;
`

const TextArea = styled.textarea`
  ${InputBase}

  width: 100%;
  resize: vertical;
`

const IconViewBase = css`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  width: 50px;
`

const PreIconView = styled.span`
  ${IconViewBase}
  left: 0;
`

const PostIconView = styled.span`
  ${IconViewBase}
  right: 0;
`

const PasswordIcon = styled(Icon.Solid)`
  color: var(--grey-500);
  cursor: pointer;
`

export const TextField = React.forwardRef<
  HTMLTextAreaElement | HTMLInputElement,
  Props
>(
  (
    { rows, multiline = false, className, preIcon, postIcon, ...rest },
    ref,
  ): JSX.Element => {
    return (
      <Container className={className} preIcon={preIcon} postIcon={postIcon}>
        {preIcon && <PreIconView>{preIcon}</PreIconView>}
        {multiline ? (
          <TextArea
            ref={ref as React.ForwardedRef<HTMLTextAreaElement>}
            rows={rows}
            {...rest}
          />
        ) : (
          <Field ref={ref as React.ForwardedRef<HTMLInputElement>} {...rest} />
        )}
        {postIcon && <PostIconView>{postIcon}</PostIconView>}
      </Container>
    )
  },
)

TextField.displayName = 'TextField'

export const PasswordField = React.forwardRef<HTMLInputElement, Props>(
  (props, ref): JSX.Element => {
    const [visible, setVisible] = useState(false)

    return (
      <TextField
        ref={ref}
        {...props}
        type={visible ? 'text' : 'password'}
        postIcon={
          <PasswordIcon
            name={visible ? 'eyeSlash' : 'eye'}
            size={20}
            onClick={() => setVisible(!visible)}
          />
        }
      />
    )
  },
)

PasswordField.displayName = 'PasswordField'
