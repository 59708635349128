import React, {
  useState,
  useCallback,
  forwardRef,
  useImperativeHandle,
  useMemo,
  useEffect,
} from 'react'
import noop from 'lodash/noop'
import { IProposal, ProposalStatus } from '@/models/IProposal'
import { PaymentPopup } from '@/components/ui'
import { showToast } from '@/utils/toast'
import useApi from '@/hooks/useApi'
import { IUserVisaWizardPayment } from '@/models/IUserVisaWizardPayment'
import { WizardPaymentIntent } from '@/utils/Api/payments.service'
import { PaymentReason } from '@/constants/payments'
import { UpdateProposalRequest } from '@/utils/Api/wizard.service'
import { IAdvisor } from '@/models/IAdvisor'
import { Popup } from '@/components/uiJar/popup'
import { ConfirmProposalPayment } from '@/components/myCases/RequestPayment/ConfirmProposalPayment'
import { CreateProposal } from '../Popups/CreateProposal'
import { ProposalSentPopup } from '../Popups/Congratulations/proposalSent'
import { IDraftProposal } from '../Popups/CreateProposal/types'
import { CongratulationsPopup } from '../Popups'
import { UserViewProposal } from '../Popups/CreateProposal/UserViewProposal'

export const CreateProposalFlow = forwardRef<
  { trigger: (proposalId?: string) => void },
  {
    proposals: IProposal[] | null
    advisorProfile: IAdvisor | null | undefined
    userWizardId?: string
    mode: 'advisor' | 'user'
    onUpdateProposals: (data: IProposal) => void
  }
>(
  (
    { proposals, advisorProfile, userWizardId, mode, onUpdateProposals },
    ref,
  ) => {
    const { api, status } = useApi()
    const [isTriggered, setTriggered] = useState(false)
    const [proposalToEdit, setProposalToEdit] = useState<string | null>(null)
    const [draftProposal, setDraftProposal] = useState<IDraftProposal>()
    const [step, setStep] = useState(0)
    const [paymentId, setPaymentId] = useState<string>()

    useImperativeHandle(
      ref,
      () => {
        return {
          trigger: (proposalId?: string) => {
            setTriggered(true)
            setProposalToEdit(proposalId || null)
          },
        }
      },
      [],
    )

    useEffect(() => {
      const flow = new URLSearchParams(window.location.search).get('flow')
      if (flow && flow.startsWith('payment-')) {
        try {
          setPaymentId(flow.split('-')[1])
        } catch (e) {
          console.error('Error getting payment id', e)
        }
        setStep(2)
        setTriggered(true)
      }
    }, [])

    const handleClose = () => {
      setTriggered(false)
      setProposalToEdit(null)
      setDraftProposal(undefined)
    }

    const proposal = useMemo(() => {
      if (!proposals) return undefined
      return proposals.find((p) => p.id === proposalToEdit)
    }, [proposals, proposalToEdit])

    const handleInitialPayment = (data: IDraftProposal) => {
      setStep(1)
      setDraftProposal(data)
    }

    const handleCreateProposal = useCallback(
      (data: IDraftProposal) => {
        const body = {
          userWizardId: data?.userWizardId || '',
          prices: data?.prices || [],
          summary: data?.summary,
          initialPayment: data?.initialPayment,
        }

        api.advisor.createProposal(body).then((data) => {
          setStep(3)
          showToast({
            type: 'success',
            message: 'Proposal created',
          })

          onUpdateProposals(data.proposal)
          setDraftProposal(undefined)
        })
      },
      [api.advisor, onUpdateProposals],
    )

    const handleUpdateProposal = useCallback(
      (data: IDraftProposal) => {
        const body: UpdateProposalRequest = {
          prices: data?.prices || [],
          summary: data?.summary,
          initialPayment: data?.initialPayment || null,
          status: ProposalStatus.Updated,
        }

        proposal &&
          api.wizard
            .updateProposal(proposal?.id, body)
            .then((response) => {
              setStep(0)
              setTriggered(false)
              showToast({
                type: 'success',
                message: 'Proposal updated',
              })

              onUpdateProposals(response.proposal)
              setDraftProposal(undefined)
            })
            .catch(noop)
      },
      [api.wizard, onUpdateProposals, proposal],
    )

    const returnUrl = `${
      new URL(location.pathname, location.href).href
    }?flow=payment-${paymentId}`

    if (!isTriggered) return null

    return (
      <>
        <Popup fullScreenInMobile isOpen={step === 0}>
          {mode === 'advisor' ? (
            <CreateProposal
              onClose={() => {
                handleClose()
              }}
              proposal={proposal}
              draftProposal={draftProposal}
              isLoading={status === 'posting'}
              userWizardId={userWizardId}
              platformFee={advisorProfile?.platformFee}
              onPaymentRequest={(data: IDraftProposal) => {
                handleInitialPayment(data)
              }}
            />
          ) : (
            <UserViewProposal
              onClose={() => setTriggered(false)}
              proposal={proposal}
              onUpdate={(data: IProposal) => onUpdateProposals(data)}
              onPayment={(data: IUserVisaWizardPayment) => {
                setPaymentId(data.id)
                setStep(2)
              }}
            />
          )}
        </Popup>
        <Popup fullScreenInMobile isOpen={step === 1}>
          <ConfirmProposalPayment
            isLoading={status === 'posting'}
            onClose={() => {
              handleClose()
              setStep(0)
            }}
            onSendProposal={(body: IDraftProposal) => {
              proposal ? handleUpdateProposal(body) : handleCreateProposal(body)
            }}
            platformFee={advisorProfile?.platformFee}
            proposal={draftProposal}
            initialAmount={proposal?.initialPayment}
            onBack={() => setStep(0)}
            mode={draftProposal?.hasInitialPayment ? 'request' : 'preview'}
            editing={!!proposal}
          />
        </Popup>
        <Popup isOpen={step === 2}>
          {paymentId && (
            <PaymentPopup<WizardPaymentIntent>
              type={PaymentReason.WizardPayment}
              wizardPaymentId={paymentId}
              returnUrl={returnUrl}
              onSuccess={() => {
                setStep(4)
              }}
              onClose={() => {
                setPaymentId(undefined)
                setTriggered(false)
                setStep(0)
              }}
            />
          )}
        </Popup>
        <Popup fullScreenInMobile isOpen={step === 3}>
          <ProposalSentPopup
            title="Congratulations"
            message={`Your proposal has been sent. Feel free to keep communicating with your client in messages!`}
            noteTitle="Initial payment request"
            note="You can see the status of your initial payment request in the payment tab."
            onClose={() => {
              setTriggered(false)
              setStep(0)
            }}
            mode={draftProposal?.hasInitialPayment ? 'request' : 'preview'}
          />
        </Popup>
        <Popup isOpen={step === 4}>
          <CongratulationsPopup
            title={`Congratulations`}
            message="Your payment has been received"
            onClose={() => {
              window.location.href = `${
                new URL(location.pathname, location.href).href
              }`
            }}
          />
        </Popup>
      </>
    )
  },
)

CreateProposalFlow.displayName = 'CreateProposalFlow'
