import { DateTime } from 'luxon'
import { IChatV2 } from '@/models/IChatV2'
import { IUser } from '@/models/IUser'
import { UserRole } from '@/constants/users'
class Helper {
  formatName = (fName?: string, lName?: string) => {
    const fullName =
      fName || lName ? `${fName || ''} ${lName || ''}`.trim() : ''

    return fullName
  }

  formatAdvisorName = (fName?: string, lName?: string) => {
    if (fName) {
      return fName
    } else if (lName) {
      return lName
    }
    return ''
  }

  formatTime = (time: string) => {
    const timeStamp = DateTime.fromISO(time)

    return timeStamp.toRelative()
  }

  initials = (fName: string, lName?: string, format = false) => {
    const name = format ? this.formatName(fName, lName) : fName

    return name?.slice(0, 2)
  }

  formatUsername = (username: string) => {
    return `@${username}`
  }

  isVideo = (mimeType: string) => {
    const videoTypes = [
      'video/x-flv',
      'video/mp4',
      'application/x-mpegURL',
      'video/MP2T',
      'video/3gpp',
      'video/quicktime',
      'video/x-msvideo',
      'video/x-ms-wmv',
    ]

    return videoTypes.includes(mimeType)
  }

  pluralize = (
    count: number,
    noun: string,
    pluralNoun = '',
    suffix = 's',
    showCount = true,
  ) => {
    const properNoun = pluralNoun ? pluralNoun : noun

    const pluralized = `${showCount ? `${count} ` : ''}${
      count !== 1 ? `${properNoun}${suffix}` : noun
    }`

    return pluralized
  }

  pollPercentage = (totalVotes: number, votes: number) =>
    (votes / totalVotes) * 100

  hasExcerpt = (text: string, length = 300) => text && text.length > length

  getExcerpt = (text: string, enabled = true, maxLength = 300) => {
    const excerpt =
      this.hasExcerpt(text, maxLength) && enabled
        ? `${text.slice(0, maxLength)} ...`
        : text

    return excerpt
  }

  getAge = (time: string) => {
    const date = DateTime.fromISO(time)

    const age = DateTime.now().diff(date, ['years']).toObject()

    return age.years
  }

  returnFileURL = (file: File) => URL.createObjectURL(file)

  generateMentionMarkup = (id: string, username: string) => {
    return `@[${username}](${id})`
  }

  stringifyMention = (text?: string) => {
    const mentionRegex = /@\[([^\]]+)\]\(([^)]+)\)/g

    if (text) {
      const stringified = text.replace(mentionRegex, '@$1')

      return stringified
    }

    return ''
  }

  getParagraphs = (text: string) => text.split('\n')

  formatedTime = (date: number | Date): string => {
    const now = new Date()
    const messageDate = new Date(date)
    const diffInSeconds = Math.floor(
      (now.getTime() - messageDate.getTime()) / 1000,
    )

    if (diffInSeconds < 60) return 'now'
    if (diffInSeconds < 3600) return `${Math.floor(diffInSeconds / 60)}min`
    if (diffInSeconds < 86400) return `${Math.floor(diffInSeconds / 3600)}h`
    if (diffInSeconds < 604800) return `${Math.floor(diffInSeconds / 86400)}d`
    if (diffInSeconds < 2592000) return `${Math.floor(diffInSeconds / 604800)}w`
    if (diffInSeconds < 31536000)
      return `${Math.floor(diffInSeconds / 2592000)}m`
    return `${Math.floor(diffInSeconds / 31536000)}y`
  }

  formatedTimeOfMessage = (date: string): string => {
    const now = new Date()
    const messageDate = new Date(date)
    const diffInSeconds = Math.floor(
      (now.getTime() - messageDate.getTime()) / 1000,
    )
    if (diffInSeconds < 60) return 'now'
    if (diffInSeconds < 3600) return `${Math.floor(diffInSeconds / 60)} min ago`
    if (diffInSeconds < 86400)
      return `${Math.floor(diffInSeconds / 3600)} h ago`
    if (diffInSeconds < 604800)
      return `${Math.floor(diffInSeconds / 86400)} d ago`
    if (diffInSeconds < 2592000)
      return `${Math.floor(diffInSeconds / 604800)} w ago`
    if (diffInSeconds < 31536000)
      return `${Math.floor(diffInSeconds / 2592000)} m ago`
    return `${Math.floor(diffInSeconds / 31536000)} y ago`
  }
  getResourceUrl = (fileId: string, chatId: string, token: string) => {
    const baseUrl = process.env.NEXT_PUBLIC_API_BASE
    return `${baseUrl}/chats-v2/${chatId}/files/${fileId}?_auth=${token}`
  }
  getUserAvatar = (avatarId: string): string => {
    const baseUrl = process.env.NEXT_PUBLIC_API_BASE
    return `${baseUrl}/users/files/${avatarId}`
  }

  getUserDisplayName = (user?: IUser) =>
    [user?.firstName, user?.lastName].filter(Boolean).join(' ') || ''

  getChatTitle = (chat: IChatV2, user: IUser): string => {
    if (!chat || !user) return ''

    if (chat.participants?.length! > 2) {
      return (
        chat.title ||
        chat.participants
          ?.map((participant) =>
            helper.getUserDisplayName(participant.user as IUser),
          )
          .join(', ')
      )
    }

    return (
      chat.participants
        ?.filter(
          (participant) =>
            participant.user?.firstName !== user?.firstName ||
            participant.user?.lastName !== user?.lastName,
        )
        .map((participant) =>
          helper.getUserDisplayName(participant.user as IUser),
        )
        .join(', ') || ''
    )
  }

  getAvatarUrl = (
    chat?: IChatV2,
    currentUserId?: string,
    token?: string,
  ): string => {
    if (!chat?.id || !currentUserId || !token) {
      console.log('Invalid inputs provided to getAvatarUrl')
      return ''
    }

    if (chat.type === 'private') {
      const avatar = chat.participants.filter(
        (participant) => participant.userId !== currentUserId,
      )[0]?.user?.avatar as string | { id: string } | undefined

      if (avatar) {
        const avatarId =
          typeof avatar === 'object' && avatar !== null ? avatar.id : avatar
        return this.getResourceUrl(avatarId, chat.id, token)
      }
    } else if (chat.imageFileId) {
      return this.getResourceUrl(chat.imageFileId, chat.id, token)
    }

    return ''
  }

  // Function to get the ordinal suffix for a given day
  getOrdinalSuffix = (day: number) => {
    if (day > 3 && day < 21) return 'th'
    switch (day % 10) {
      case 1:
        return 'st'
      case 2:
        return 'nd'
      case 3:
        return 'rd'
      default:
        return 'th'
    }
  }

  isAdmin = (role?: UserRole) => role === UserRole.Lawyer
}

export const helper = new Helper()
