import styled, { css, FlattenSimpleInterpolation } from 'styled-components'
import { textVariants } from '@/components/ui/Text'
import { InputSize } from '../InputField'
import { thinModalScrollbar } from '../utilityStyles'
import { TextareaProps } from '.'

export function getSizeCss(): Record<InputSize, FlattenSimpleInterpolation> {
  return {
    1: css`
      ${textVariants['regular-3']}
      padding: 8px;
      border-radius: 6px;
      &::placeholder {
        ${textVariants['regular-3']}
      }
    `,
    2: css`
      ${textVariants['regular-3']}
      padding: 10px;
      border-radius: 8px;
      &::placeholder {
        ${textVariants['regular-3']}
      }
    `,
    3: css`
      ${textVariants['regular-4']}
      padding: 12px;
      border-radius: 10px;
      &::placeholder {
        ${textVariants['regular-4']}
      }
    `,
    4: css`
      ${textVariants['regular-4']}
      padding: 16px;
      border-radius: 12px;
      &::placeholder {
        ${textVariants['regular-4']}
      }
    `,
  }
}

export function getOutlineCss(hasError = false) {
  return css`
    color: var(--neutral-alpha-12);
    background-color: var(--panel-1);
    border: 1px solid
      ${hasError ? 'var(--danger-11)' : 'var(--neutral-alpha-6)'};
    box-shadow: ${hasError ? '0px 0px 0px 2px rgba(255,0,0,0.1)' : 'none'};
    &:hover {
      background-color: var(--panel-3);
    }
    &::placeholder {
      color: var(--neutral-alpha-11);
    }
    &:focus {
      outline: none;
      border-color: ${hasError ? 'var(--danger-11)' : 'var(--neutral-12)'};
      box-shadow: 0px 0px 0px 2px
        ${hasError ? 'rgba(255,0,0,0.1)' : 'rgba(2,2,52,0.08)'};
    }
  `
}

export const getSoftCss = (hasError = false) => css`
  color: var(--neutral-alpha-11);
  background-color: ${hasError
    ? 'var(--danger-alpha-3)'
    : 'var(--neutral-alpha-3)'};
  border: 1px solid transparent;
  &:hover {
    ${!hasError && 'background-color: var(--neutral-alpha-4);'}
  }
  &::placeholder {
    color: var(--neutral-alpha-11);
  }
  &:focus {
    outline: none;
    color: var(--neutral-12);
    background-color: transparent;
    border-color: var(--neutral-alpha-6);
    &::placeholder {
      color: var(--neutral-12);
    }
  }
`

function getVariantCss(variant: 'outline' | 'soft', hasError = false) {
  return {
    outline: getOutlineCss(hasError),
    soft: getSoftCss(hasError),
  }[variant]
}

export const StyledTextarea = styled.textarea<TextareaProps>`
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  text-overflow: ellipsis;
  ${thinModalScrollbar}
  ${(p) => getSizeCss()[p.size || 2]}
   ${(p) => getVariantCss(p.variant || 'outline', p.hasError)}
`

export const TextareaInputWrapper = styled.div`
  position: relative;
  &:hover {
    svg {
      color: var(--neutral-alpha-11);
    }
  }
`

export const TextareaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const Label = styled.label`
  color: var(--neutral-12);
  ${textVariants['medium-3']}
  margin-bottom: 8px;
`
export const OptionalLabel = styled.span`
  margin-left: 1px;
  font-weight: 500;
  color: var(--neutral-11);
`
export const RequiredStar = styled.span`
  color: var(--primary-11);
  margin-left: 2px;
  font-weight: 500;
`
export const HelperTextWrapper = styled.div<{ hasError: boolean }>`
  display: flex;
  align-items: center;
  margin-top: 8px;
  color: ${(p) => (p.hasError ? 'var(--danger-11)' : 'var(--neutral-11)')};
`
export const HelperText = styled.span`
  ${textVariants['regular-2']}
  margin-left: 4px;
`
