/* This Should be used within CalProvider */

import React from 'react'
import { Booker } from '@calcom/atoms'
import {
  BookerPlatformWrapperAtomPropsForIndividual,
  BookerPlatformWrapperAtomPropsForTeam,
} from '@calcom/atoms/dist/packages/platform/atoms/booker/BookerPlatformWrapper'
import { Popup, PopupTopBar } from '@/components/uiJar/popup'
import { BookerContainer, BookerContentWrapper } from './styles'

type BookerPopupProps = {
  isOpen: boolean
  onClose: () => void
  mode?: 'advisor' | 'client'
  bookerProps:
    | BookerPlatformWrapperAtomPropsForIndividual
    | BookerPlatformWrapperAtomPropsForTeam
}

const BookerPopup = (props: BookerPopupProps) => {
  const { isOpen, onClose, bookerProps, mode } = props
  return (
    <Popup fullScreenInMobile isOpen={isOpen}>
      <BookerContainer>
        <PopupTopBar onClose={onClose}>Schedule a call</PopupTopBar>
        <BookerContentWrapper>
          <Booker
            view="MONTH_VIEW"
            customClassNames={{
              bookerContainer: `booker-container ${
                mode && mode == 'advisor' && 'advisor-booker-container'
              }`,
              eventMetaCustomClassNames: {
                eventMetaTimezoneSelect: 'event-meta-timezone-select',
              },
            }}
            {...bookerProps}
          />
        </BookerContentWrapper>
      </BookerContainer>
    </Popup>
  )
}

export default BookerPopup
