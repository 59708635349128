import React, {
  Dispatch,
  forwardRef,
  SetStateAction,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import { DateTime } from 'luxon'
import { Flex, Text } from '@/components/ui'
import useApi from '@/hooks/useApi'
import { Popup } from '@/components/uiJar/popup'
import {
  DefaultPopupContainer,
  DefaultPopupContentWrapper,
  DefaultPopupFooter,
} from '@/components/uiJar/utilityStyles'
import { Button } from '@/components/uiJar/Button'
import { ICalBooking } from '@/models/ICalBooking'
import { IUser } from '@/models/IUser'
import { helper } from '@/utils/helper'

export const CancelBookingFlow = forwardRef<
  { trigger: () => void },
  {
    advisor: IUser
    meeting: ICalBooking | null
    setBookedMeeting: Dispatch<SetStateAction<ICalBooking | null>>
  }
>(({ meeting, setBookedMeeting, advisor }, ref) => {
  const { api } = useApi()
  const [isTriggered, setTriggered] = useState(false)
  const [loading, setLoading] = useState(false)

  const formattedDate = useMemo(() => {
    if (meeting) {
      const dateTime = DateTime.fromISO(meeting?.dateFrom as string).setZone(
        'local',
      )
      const day = dateTime.day
      const ordinalSuffix = helper.getOrdinalSuffix(day)
      return dateTime.toFormat(
        `MMMM d'${ordinalSuffix}', yyyy 'and' h:mm 'local time'`,
      )
    }
    return ''
  }, [meeting])

  const handleClose = () => {
    setTriggered(false)
  }

  const handleCancelCall = async () => {
    setLoading(true)
    api.wizard
      .cancelBooking(meeting?.meetingId as string)
      .then(() => {
        setBookedMeeting(null)
        handleClose()
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useImperativeHandle(
    ref,
    () => {
      return {
        trigger: () => setTriggered(true),
      }
    },
    [],
  )
  if (!isTriggered) return null
  return (
    <>
      <Popup isOpen>
        <DefaultPopupContainer>
          <DefaultPopupContentWrapper>
            <Flex direction="column" gap={20}>
              <Flex direction="column" gap={4}>
                <Text variant="semibold-4">Cancel booking?</Text>
                <Text variant="regular-4" colorVar="neutral-11">
                  Are you sure you want to cancel upcoming call with{' '}
                  {advisor.firstName} on {formattedDate}? This action will free
                  up a time slot for future bookings and will notify{' '}
                  {advisor.firstName} about cancelation.
                </Text>
              </Flex>
            </Flex>
          </DefaultPopupContentWrapper>
          <DefaultPopupFooter>
            <Button
              variant="outline"
              size={3}
              fullWidth
              colorTheme="neutral"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              variant="solid"
              size={3}
              fullWidth
              colorTheme="danger"
              disabled={loading}
              isLoading={loading}
              onClick={handleCancelCall}
            >
              Confirm
            </Button>
          </DefaultPopupFooter>
        </DefaultPopupContainer>
      </Popup>
    </>
  )
})

CancelBookingFlow.displayName = 'CancelBookingFlow'
