import React, { useState } from 'react'
import ReactSelect, { GroupBase, Props } from 'react-select'
import getSelectStyles, {
  CustomClearIndicator,
  CustomDropdownIndicator,
  CustomIndicatorSeparator,
  CustomMenuList,
  CustomMultiValueRemove,
} from './customize'
import {
  HelperText,
  HelperTextWrapper,
  Label,
  OptionalLabel,
  SelectInputFieldWrapper,
  SelectInputWrapper,
  StyledIconHolder,
} from './styles'
import {
  FlagIcon,
  Icon,
  IconType,
  OtherIcon,
  OutlineIcon,
  SolidIcon,
} from '../../ui/Icon'
import IconRenderer from '../helper/IconRenderer'
import type {} from 'react-select/base'
import { useTheme } from '@/contexts/Theme'

export type SelectInputSize = 1 | 2 | 3 | 4

interface IconProps {
  type: IconType
  name?: OtherIcon | OutlineIcon | SolidIcon | FlagIcon
}

const startIconSize: Record<SelectInputSize, number> = {
  1: 20,
  2: 24,
  3: 24,
  4: 24,
}

export const iconLeftCss: Record<SelectInputSize, string> = {
  1: '8px',
  2: '8px',
  3: '12px',
  4: '16px',
}

export type IconHolderProps = {
  focused: boolean
  size: SelectInputSize
}

export type OptionType = { value: string; label: string }
export interface CustomProps {
  size: SelectInputSize
  label?: string
  hasError?: boolean
  helperText?: string
  placeholder?: string
  startIcon?: IconProps
  optional?: boolean
  isMenuLoading?: boolean
}

declare module 'react-select/base' {
  export interface Props<
    Option,
    IsMulti extends boolean,
    Group extends GroupBase<Option>,
  > {
    size: SelectInputSize
    label?: string
    hasError?: boolean
    helperText?: string
    startIcon?: IconProps
    optional?: boolean
    isMenuLoading?: boolean
    hovered?: boolean
  }
}

const SelectInput = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: Props<Option, IsMulti, Group> & CustomProps,
) => {
  const { theme } = useTheme()
  const {
    label,
    className,
    hasError,
    helperText,
    id,
    placeholder,
    options,
    optional,
    size,
    startIcon,
    onBlur,
    onFocus,
    isMenuLoading,
    components,
    ...rest
  } = props

  const [hovered, setHovered] = useState(false)
  const [focused, setFocused] = useState(false)

  const handleFocus = (event: React.FocusEvent<HTMLInputElement, Element>) => {
    setFocused(true)
    onFocus && onFocus(event)
  }

  const handleBlur = (event: React.FocusEvent<HTMLInputElement, Element>) => {
    setFocused(false)
    onBlur && onBlur(event)
  }

  return (
    <SelectInputFieldWrapper>
      {label && (
        <Label htmlFor={id}>
          {label}
          {optional && <OptionalLabel>{`(Optional)`}</OptionalLabel>}
        </Label>
      )}
      <SelectInputWrapper
        className="relative group"
        onMouseOver={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        {startIcon && (
          <StyledIconHolder size={size} focused={focused}>
            <IconRenderer
              size={size}
              sizeMap={startIconSize}
              iconName={startIcon.name}
              iconType={startIcon.type}
            />
          </StyledIconHolder>
        )}
        <ReactSelect<Option>
          // @ts-ignore
          styles={getSelectStyles(hasError, size, !!startIcon, theme)}
          placeholder={placeholder}
          instanceId={id}
          options={options}
          onFocus={handleFocus}
          onBlur={handleBlur}
          hovered={hovered}
          size={size}
          isMenuLoading={isMenuLoading}
          components={{
            // @ts-ignore
            DropdownIndicator: CustomDropdownIndicator,
            // @ts-ignore
            IndicatorSeparator: CustomIndicatorSeparator,
            // @ts-ignore
            MenuList: CustomMenuList,
            // @ts-ignore
            ClearIndicator: CustomClearIndicator,
            // @ts-ignore
            MultiValueRemove: CustomMultiValueRemove,
            ...components,
          }}
          {...rest}
        />
      </SelectInputWrapper>

      {helperText && (
        <HelperTextWrapper hasError={!!hasError}>
          <span>
            {hasError && <Icon.Solid name="circlExclamation" size={16} />}
          </span>

          <HelperText>{helperText}</HelperText>
        </HelperTextWrapper>
      )}
    </SelectInputFieldWrapper>
  )
}

export default SelectInput
