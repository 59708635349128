import React, { forwardRef, TextareaHTMLAttributes } from 'react'
import {
  HelperText,
  HelperTextWrapper,
  TextareaWrapper,
  Label,
  OptionalLabel,
  TextareaInputWrapper,
  StyledTextarea,
  RequiredStar,
} from './styles'
import { Icon } from '../../ui/Icon'

export type TextareaSize = 1 | 2 | 3 | 4

export type InputVariance = {
  variant?: 'outline' | 'soft'
  size?: TextareaSize
  hasError?: boolean
  className?: string
}

export interface TextareaProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  variant?: 'outline' | 'soft'
  size?: TextareaSize
  hasError?: boolean
  className?: string
  label?: string
  helperText?: string
  optional?: boolean
}

const Textarea: React.ForwardRefRenderFunction<
  HTMLInputElement,
  TextareaProps
> = (props, ref) => {
  const {
    label,
    className,
    hasError,
    helperText,
    id,
    optional,
    size,
    ...rest
  } = props

  return (
    <TextareaWrapper>
      {label && (
        <Label htmlFor={id}>
          {label}
          {rest.required && <RequiredStar>{`*`}</RequiredStar>}
          {optional && <OptionalLabel>{`(Optional)`}</OptionalLabel>}
        </Label>
      )}
      <TextareaInputWrapper>
        <StyledTextarea
          ref={ref as any}
          size={size}
          hasError={hasError}
          id={id}
          {...rest}
        />
      </TextareaInputWrapper>
      {helperText && (
        <HelperTextWrapper hasError={!!hasError}>
          <span>
            {hasError && <Icon.Solid name="circlExclamation" size={16} />}
          </span>

          <HelperText>{helperText}</HelperText>
        </HelperTextWrapper>
      )}
    </TextareaWrapper>
  )
}

export default forwardRef(Textarea)
