import { AxiosInstance } from 'axios'
import { IAdvisor } from '@/models/IAdvisor'

export interface ICalCredentials {
  calUserId: number
  calAtomsAccessToken: string
}

export interface ICalRefreshToken {
  accessToken: string
}

export class CalService {
  constructor(private instance: AxiosInstance) {}

  getCredentials(id: string) {
    return this.instance
      .get<ICalCredentials>(`cal-com/credentials/${id}`)
      .then((response) => response.data)
  }

  getRefreshToken(id: string) {
    return this.instance
      .get<ICalRefreshToken>(`cal-com/refresh/${id}`)
      .then((response) => response.data)
  }

  getAdvisorProfile(id: string) {
    return this.instance
      .get<IAdvisor>(`/advisor/profile/${id}`)
      .then((response) => response.data)
  }
}
