import React, { Fragment, use, useEffect, useMemo, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { Flex, Text } from '@/components/ui'
import { Prices, ProposalStatus } from '@/models/IProposal'
import {
  DashedDivider,
  DefaultPopupContainer,
  DefaultPopupContentWrapper,
  DefaultPopupFooter,
  Divider,
  FullScreenMobileContainer,
  FullScreenPopupContentWrapper,
} from '@/components/uiJar/utilityStyles'
import { Popup, PopupTopBar } from '@/components/uiJar/popup'
import { Button } from '@/components/uiJar/Button'
import { IconLink } from '@/components/uiJar/IconLink'
import { ButtonLink } from '@/components/uiJar/ButtonLink'
import InputField from '@/components/uiJar/InputField'
import Textarea from '@/components/uiJar/Textarea'
import Switch from '@/components/uiJar/Switch'
import getProposalStatus from '@/contexts/Wizard/getProposalStatus'
import {
  ProposalPrices,
  PricesHeader,
  PricesContainer,
  PriceField,
  TotalsContainer,
  ServiceItemWrapper,
  PriceItemWrapper,
  ActionItemWrapper,
  RequestPaymentNotice,
  WarningPopupContent,
} from './styles'
import { ICreateProposalProps } from './types'

type CreateProposalInput = {
  prices: Prices[]
  summary: string
  hasInitialPayment: boolean
  proposalTotal: string
  techFee: string
  totalEarning: string
}

export const CreateProposal: React.FC<ICreateProposalProps> = ({
  onClose,
  proposal,
  userWizardId,
  isLoading,
  platformFee = 30,
  draftProposal,
  onPaymentRequest,
}) => {
  const proposalPrices = draftProposal
    ? draftProposal?.prices
    : proposal?.prices
  const proposalSummary = draftProposal
    ? draftProposal?.summary
    : proposal?.summary
  const proposalInitialPayment = draftProposal
    ? draftProposal.hasInitialPayment
    : !!proposal?.initialPayment

  const [viewHowItWorks, setViewHowItWorks] = useState(false)
  const [showIntialPaymentWarning, setShowIntialPaymentWarning] =
    useState(false)

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isValid },
  } = useForm<CreateProposalInput>({
    defaultValues: {
      prices: proposalPrices || [{ title: '', price: 0 }],
      summary: proposalSummary || '',
      hasInitialPayment: proposalInitialPayment || false,
    },
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'prices',
  })

  const prices = watch('prices')
  const hasInitialPayment = watch('hasInitialPayment')
  const stringifiedPrices = JSON.stringify(prices)
  const canBeWithdrawn = useMemo(() => {
    if (!proposal) return false
    const status = getProposalStatus(proposal)
    if (
      status === ProposalStatus.Sent ||
      status === ProposalStatus.Rejected ||
      status === ProposalStatus.Updated
    ) {
      return true
    }
    return false
  }, [proposal])

  useEffect(() => {
    const pricesTotal = prices
      .map((item) => item.price)
      .reduce((a, b) => a + b, 0)
    const total = pricesTotal
    const crFee = (total * platformFee) / 100
    const totalEarning = total - crFee

    setValue('proposalTotal', Math.ceil(total).toLocaleString())
    setValue('techFee', Math.ceil(crFee).toLocaleString())
    setValue('totalEarning', Math.ceil(totalEarning).toLocaleString())
  }, [platformFee, prices, stringifiedPrices, setValue])

  const toggleViewHowItWorks = () => {
    setViewHowItWorks(!viewHowItWorks)
  }

  const toggleIntialPaymentWarning = () => {
    setShowIntialPaymentWarning(!showIntialPaymentWarning)
  }

  const onSubmit = (data: CreateProposalInput) => {
    const proposalBody = {
      ...(!proposal && { userWizardId: userWizardId || '' }),
      hasInitialPayment: data.hasInitialPayment,
      prices: data.prices,
      summary: data.summary,
    }
    onPaymentRequest(proposalBody)
  }

  if (!showIntialPaymentWarning) {
    return (
      <FullScreenMobileContainer as="form" onSubmit={handleSubmit(onSubmit)}>
        {!viewHowItWorks ? (
          <PopupTopBar onClose={onClose}>
            {proposal ? 'Edit Proposal' : 'New Proposal'}
          </PopupTopBar>
        ) : (
          <PopupTopBar onClose={toggleViewHowItWorks}>
            How it works?
          </PopupTopBar>
        )}
        <FullScreenPopupContentWrapper>
          {!viewHowItWorks ? (
            <Flex gap={24} direction="column">
              <Controller
                rules={{ required: 'Summary is required' }}
                name="summary"
                control={control}
                render={({ field }) => (
                  <Textarea
                    hasError={!!errors.summary}
                    helperText={errors.summary?.message}
                    rows={4}
                    label="Summary"
                    size={2}
                    variant="outline"
                    placeholder="Add your message to the proposal"
                    {...field}
                  />
                )}
              />

              <ProposalPrices>
                <PricesHeader>
                  <ServiceItemWrapper>
                    <Text variant="medium-3">Services</Text>
                  </ServiceItemWrapper>
                  <PriceItemWrapper>
                    <Text variant="medium-3">Price</Text>
                  </PriceItemWrapper>
                  <ActionItemWrapper />
                </PricesHeader>
                <PricesContainer>
                  {fields.map((item, index) => (
                    <PriceField key={item.id} alignItems="center" gap={8}>
                      <ServiceItemWrapper>
                        <Controller
                          rules={{ required: 'Item name is required' }}
                          name={`prices.${index}.title`}
                          control={control}
                          render={({ field }) => (
                            <InputField
                              size={2}
                              variant="outline"
                              placeholder="Item name"
                              hasError={!!errors.prices?.[index]?.title}
                              helperText={
                                errors.prices?.[index]?.title?.message
                              }
                              {...field}
                            />
                          )}
                        />
                      </ServiceItemWrapper>
                      <PriceItemWrapper>
                        <Controller
                          rules={{ required: 'Price is required' }}
                          name={`prices.${index}.price`}
                          control={control}
                          render={({ field: { onChange, ...rest } }) => (
                            <InputField
                              size={2}
                              variant="outline"
                              type="number"
                              placeholder="Price"
                              min={0}
                              hasError={!!errors.prices?.[index]?.price}
                              helperText={
                                errors.prices?.[index]?.price?.message
                              }
                              onChange={(e) => {
                                const numericValue = e.target.value.replace(
                                  /\D/g,
                                  '',
                                )
                                if (numericValue.length > 0) {
                                  onChange(Number(numericValue))
                                } else {
                                  onChange(numericValue)
                                }
                              }}
                              {...rest}
                            />
                          )}
                        />
                      </PriceItemWrapper>
                      <ActionItemWrapper $disabled={prices.length < 2}>
                        <IconLink
                          size={3}
                          colorTheme="neutral"
                          type="Outline"
                          name="trashCan2"
                          onClick={() => remove(index)}
                        />
                      </ActionItemWrapper>
                    </PriceField>
                  ))}
                  <ButtonLink
                    icon={{ type: 'Outline', startIconName: 'plusSmall' }}
                    weight="medium"
                    size={2}
                    colorTheme="neutral"
                    onClick={() => append({ title: '', price: 0 })}
                    type="button"
                  >
                    Add Item
                  </ButtonLink>
                </PricesContainer>
              </ProposalPrices>
              <Divider />
              <RequestPaymentNotice>
                <Flex gap={12}>
                  <Switch
                    size={2}
                    isActive={hasInitialPayment}
                    onSwitch={() => {
                      setValue('hasInitialPayment', !hasInitialPayment)
                    }}
                    type="button"
                  />
                  <Text variant="medium-3">
                    Require an initial payment after accepting proposal?
                  </Text>
                </Flex>
                <ButtonLink
                  className="how-tip"
                  colorTheme="primary"
                  weight="medium"
                  size={1}
                  icon={{ startIconName: 'circleInfo', type: 'Outline' }}
                  highContrast={false}
                  onClick={toggleViewHowItWorks}
                >
                  How it works?
                </ButtonLink>
              </RequestPaymentNotice>
              <TotalsContainer direction="column" gap={16}>
                <Flex justifyContent="space-between">
                  <Text variant="medium-3">Proposal total</Text>
                  <Text variant="medium-3">${watch('proposalTotal')}</Text>
                </Flex>
                <DashedDivider />
                <Flex justifyContent="space-between">
                  <Text variant="medium-3">
                    Total Technology Fee {platformFee}%
                  </Text>
                  <Text variant="medium-3">${watch('techFee')}</Text>
                </Flex>
                <DashedDivider />
                <Flex justifyContent="space-between">
                  <Text variant="semibold-4">Your Total Earning</Text>
                  <Text variant="semibold-4">${watch('totalEarning')}</Text>
                </Flex>
              </TotalsContainer>
            </Flex>
          ) : (
            <Flex direction="column" gap={4}>
              <Text variant="medium-4">
                Require an initial payment from the client after they accept
                your proposal
              </Text>
            </Flex>
          )}
        </FullScreenPopupContentWrapper>

        <DefaultPopupFooter>
          {!viewHowItWorks ? (
            <Button
              key={'continue'}
              variant="solid"
              size={3}
              colorTheme="neutral"
              isLoading={isLoading}
              disabled={!isValid || isLoading}
              type="button"
              fullWidth
              onClick={() => {
                if (!hasInitialPayment) {
                  toggleIntialPaymentWarning()
                } else {
                  handleSubmit(onSubmit)()
                }
              }}
            >
              Continue
            </Button>
          ) : (
            <Button
              key="back"
              variant="outline"
              size={3}
              colorTheme="neutral"
              type="button"
              onClick={toggleViewHowItWorks}
              fullWidth
            >
              Back
            </Button>
          )}
        </DefaultPopupFooter>
      </FullScreenMobileContainer>
    )
  }

  return (
    <Fragment>
      <Popup isOpen={showIntialPaymentWarning}>
        <DefaultPopupContainer>
          <DefaultPopupContentWrapper>
            <WarningPopupContent>
              <Text variant="semibold-4">
                Require an initial payment after accepting proposal
              </Text>
              <Text variant="regular-3" colorVar="neutral-11">
                Are you certain you don’t want to request an initial payment
                after the client accepts? We recommend securing a partial
                payment upfront to confirm and engage services effectively.
              </Text>
            </WarningPopupContent>
          </DefaultPopupContentWrapper>
          <DefaultPopupFooter>
            <Flex gap={8} className="full-width">
              <Button
                onClick={toggleIntialPaymentWarning}
                fullWidth
                variant="outline"
                size={3}
                colorTheme="neutral"
              >
                Back
              </Button>
              <Button
                onClick={handleSubmit(onSubmit)}
                fullWidth
                variant="solid"
                size={3}
                colorTheme="neutral"
              >
                Continue
              </Button>
            </Flex>
          </DefaultPopupFooter>
        </DefaultPopupContainer>
      </Popup>
    </Fragment>
  )
}
