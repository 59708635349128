import styled from 'styled-components'
import {
  DefaultPopupContentWrapper,
  DefaultPopupFooter,
} from '@/components/uiJar/utilityStyles'

export const Content = styled(DefaultPopupContentWrapper)`
  padding-bottom: 54px;
`

export const Actions = styled(DefaultPopupFooter)`
  flex-direction: row;
`
