import { AxiosInstance } from 'axios'
import { ExploreAllContinent, ExploreAllType } from '@/models/IExplore'
import { IUser } from '@/models/IUser'
interface MapPoint {
  geometry: {
    coordinates: [number, number] // [longitude, latitude]
    type?: string // "Point" typically for GeoJSON
  }
  properties: {
    // City related
    cityId?: string
    cityName?: string
    countryName?: string
    population?: number

    // Cluster related
    point_count?: number
    cluster_id?: string
    expansionZoom?: number

    // User related
    userId?: string
    userName?: string
    userAvatar?: string

    // Other properties
    coordinatesToFit?: any
    markerType?: 'city' | 'user' | 'cluster' | 'custom'
    isActive?: boolean
    description?: string
    markerColor?: string
    zIndex?: number

    // For displaying information
    title?: string
    subtitle?: string
    iconName?: string
  }
  id?: string | number // Unique identifier
  type?: string // "Feature" typically for GeoJSON
}

export interface UserLocationResponse {
  city: string
  country: string
}

export interface IAddUserConnectInfoPayload {
  latitude: number
  longitude: number
}

export interface MapPointsRequestObj {
  start: {
    latitude: number
    longitude: number
  }
  end: {
    latitude: number
    longitude: number
  }
  zoom: number
}

export interface IExploreFilters {
  text?: string
  type?: ExploreAllType
  countryId?: string
  searchTypeCity?: boolean
  searchTypeCountry?: boolean
  continent?: ExploreAllContinent
  livingCostMin?: number
  livingCostMax?: number
  visaDigitalNomad?: boolean
  visaTourist?: boolean
  rating5?: boolean
  rating4?: boolean
  rating3?: boolean
  rating2?: boolean
  rating1?: boolean
  withReviews?: boolean
  withRemoters?: boolean
  withMatch?: boolean
  withWizards?: boolean
}
export interface LocationDetails {
  city: string
  cityId: string
  country: string
  countryId: string
  latitude: number
  longitude: number
}

export class ConnectService {
  constructor(private instance: AxiosInstance) {}

  // *** Connect ***
  getConnectInfo = () =>
    this.instance.get<LocationDetails>('/users/me/location')
  optOut = () => this.instance.delete<void>('/users/me/location')
  addConnectInfo = (body: IAddUserConnectInfoPayload) =>
    this.instance.post<LocationDetails>('/users/me/location', body)
  getCurrentLocationCountry = (body: IAddUserConnectInfoPayload) =>
    this.instance.post<LocationDetails>('/users/me/location/country', body)
  getUsersNearMe = () => this.instance.get<IUser[]>('/users/search/near-me')
  getUsersByCity = (city: string) =>
    this.instance.get<IUser[]>(`/users/search/city/${city}`)
  getUsersByCityId = (id: string) =>
    this.instance.get<IUser[]>(`/users/search/city/id/${id}`)
  getOtherUserLocation = (userId: string) =>
    this.instance.get<UserLocationResponse>(`/users/${userId}/location`)
  getMapPoints = (body: MapPointsRequestObj) =>
    this.instance.post<MapPoint[]>('/users/search/location/v2', body)
}
