import React, { ButtonHTMLAttributes } from 'react'
import { SwtichButton } from './styles'

export type SwitchSize = 1 | 2 | 3

export interface SwitchProps
  extends ButtonHTMLAttributes<HTMLButtonElement | HTMLAnchorElement> {
  size: SwitchSize
  onSwitch: () => void
  isActive: boolean
}

const Switch = (props: SwitchProps) => {
  const { onSwitch, size, isActive, ...rest } = props
  return (
    <SwtichButton
      {...rest}
      $size={size}
      $isActive={isActive}
      onClick={onSwitch}
    />
  )
}

export default Switch
