import styled from 'styled-components'
import {
  FullScreenMobileContainer,
  FullScreenPopupContentWrapper,
} from '@/components/uiJar/utilityStyles'
import { Media } from '@/utils/media'

export const BookerContainer = styled(FullScreenMobileContainer)`
  width: 100%;
  ${Media.Tablet`
    width: 98vw;
  `}
  ${Media.Desktop`
    width: 1080px;
  `}
`
export const BookerContentWrapper = styled(FullScreenPopupContentWrapper)`
  padding: 0;
  ${Media.Tablet`
    padding: 20px;
    min-height: 580px;
  `}
  .booker-container {
    width: 100% !important;
  }
  .event-meta-timezone-select {
    overflow-y: visible;
  }
  .event-meta-timezone-select > div {
    max-height: 300px;
    overflow-y: auto;
    margin-left: -4px;
    ${Media.Tablet`
       max-height: 200px;
      `}
  }
`
