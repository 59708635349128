import React, { useCallback } from 'react'
import { Text } from '@/components/ui'
import useApi from '@/hooks/useApi'
import { showToast } from '@/utils/toast'
import { IProposal, ProposalStatus } from '@/models/IProposal'
import { DefaultPopupContainer } from '@/components/uiJar/utilityStyles'
import { Button } from '@/components/uiJar/Button'
import { Content, Actions } from './styles'

interface WithdrawProposalProps {
  onCancel: () => void
  proposalId?: string
  onUpdate: (proposal: IProposal) => void
}

export const WithdrawProposalPopup: React.FC<WithdrawProposalProps> = ({
  onCancel,
  proposalId,
  onUpdate,
}) => {
  const { api, status } = useApi()

  const handleWithdraw = useCallback(() => {
    proposalId &&
      api.wizard
        .updateProposal(proposalId, { status: ProposalStatus.Withdrawn })
        .then((data) => {
          onUpdate(data.proposal)

          showToast({
            type: 'success',
            message: 'Proposal withdrawn',
          })

          onCancel()
        })
  }, [proposalId, api.wizard, onUpdate, onCancel])
  return (
    <DefaultPopupContainer>
      <Content>
        <Text variant="semibold-4">Withdrawing Proposal?</Text>
        <Text variant="regular-3" colorVar="neutral-11">
          Are you sure you want to withdraw this proposal?
        </Text>
      </Content>
      <Actions>
        <Button
          variant="outline"
          colorTheme="neutral"
          size={3}
          onClick={onCancel}
          fullWidth
        >
          Cancel
        </Button>
        <Button
          isLoading={status === 'posting'}
          variant="solid"
          colorTheme="neutral"
          size={3}
          onClick={handleWithdraw}
          fullWidth
        >
          Confirm
        </Button>
      </Actions>
    </DefaultPopupContainer>
  )
}
