import styled from 'styled-components'
import { Flex, Text, textVariants } from '@/components/ui'
import { Media } from '@/utils/media'
import {
  DefaultPopupFooter,
  FullScreenPopupContentWrapper,
  thinModalScrollbar,
} from '@/components/uiJar/utilityStyles'

export const TopbarWrapper = styled(Flex)`
  width: 100%;
  flex-direction: column;
  ${Media.Tablet`
    display: none;
    `}
`

export const IconWrapper = styled.div`
  color: var(--success-9);
`

export const PopupContent = styled(Flex)<{
  variant: 'congratulation' | 'information'
}>`
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  flex-grow: 1;
  overflow-y: auto;
  ${thinModalScrollbar}
  i {
    color: ${(p) =>
      p.variant === 'congratulation'
        ? 'var(--green-500);'
        : 'var(--grey-300);'};
  }
`

export const Subtitle = styled(Text)`
  flex-grow: 1;
  ${textVariants['regular-3']}
  color: var(--neutral-11);
  p {
    margin-bottom: 20px;
  }

  ol {
    list-style: none;
    counter-reset: item;
    margin-left: 40px;

    li {
      left: 0;
      position: relative;
      counter-increment: item;
    }

    li:before {
      left: -40px;
      position: absolute;
      ${textVariants['body-m-semibold']}
      margin-right: 10px;
      content: counter(item);
      background-color: var(--grey-100);
      border-radius: 100%;
      color: var(--grey-900);
      width: 32px;
      height: 32px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }

    > li:not(:last-child) {
      margin-bottom: 32px;
    }
  }
`

export const Subscript = styled(Text)`
  color: var(--grey-500);
`

export const Actions = styled(DefaultPopupFooter)`
  border-top: none;
  ${Media.Tablet`
    border-top: 1px solid var(--neutral-alpha-4);
    `}
`

export const Note = styled(Flex)`
  border-radius: 16px;
  padding: 16px;
  background-color: var(--neutral-2);
  direction: column;
  i {
    color: var(--accent-9);
  }
`

export const PopupContentWrapper = styled(FullScreenPopupContentWrapper)`
  padding-bottom: 52px;
  ${Media.Tablet`
    min-height: auto;
  `}
`
