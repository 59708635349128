import React, {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  ElementType,
} from 'react'
import styled, { FlattenSimpleInterpolation, css } from 'styled-components'
import {
  Icon,
  IconType,
  OtherIcon,
  OutlineIcon,
  SolidIcon,
} from '@/components/ui/Icon'
import { textVariants } from '@/components/ui/Text'
import IconRenderer from '../helper/IconRenderer'

type ButtonOrAnchorProps = ButtonHTMLAttributes<HTMLButtonElement> &
  AnchorHTMLAttributes<HTMLAnchorElement>

type Weight = 'bold' | 'semibold' | 'medium' | 'regular'
type ButtonLinkSize = 1 | 2 | 3 | 4
type ButtonLinkColorTheme = 'primary' | 'neutral'

interface IconProps {
  type: IconType
  startIconName?: OtherIcon | OutlineIcon | SolidIcon
  endIconName?: OtherIcon | OutlineIcon | SolidIcon
}

export interface ButtonLinkProps extends ButtonOrAnchorProps {
  weight: Weight
  colorTheme: ButtonLinkColorTheme
  size: ButtonLinkSize
  highContrast?: boolean
  underline?: boolean
  fullWidth?: boolean
  startIcon?: React.ReactNode
  endIcon?: React.ReactNode
  as?: ElementType
  icon?: IconProps
}

const highContrastStyle: Record<
  ButtonLinkColorTheme,
  FlattenSimpleInterpolation
> = {
  primary: css`
    color: var(--primary-alpha-12);
    border-color: var(--primary-alpha-12);
    &:hover {
      color: var(--primary-alpha-11);
      border-color: var(--primary-alpha-11);
    }
  `,
  neutral: css`
    color: var(--neutral-alpha-12);
    border-color: var(--neutral-alpha-12);
    &:hover {
      color: var(--neutral-alpha-11);
      border-color: var(--neutral-alpha-11);
    }
  `,
}

const normalContrastStyle: Record<
  ButtonLinkColorTheme,
  FlattenSimpleInterpolation
> = {
  primary: css`
    color: var(--primary-alpha-11);
    border-color: var(--primary-alpha-11);
    &:hover {
      color: var(--primary-alpha-12);
      border-color: var(--primary-alpha-12);
    }
  `,
  neutral: css`
    color: var(--neutral-alpha-11);
    border-color: var(--neutral-alpha-11);
    &:hover {
      color: var(--neutral-alpha-12);
      border-color: var(--neutral-alpha-12);
    }
  `,
}

const highContrastCss: Record<
  1 | 0,
  Record<ButtonLinkColorTheme, FlattenSimpleInterpolation>
> = {
  1: highContrastStyle,
  0: normalContrastStyle,
}

const weightCss: Record<Weight, FlattenSimpleInterpolation> = {
  bold: css`
    font-weight: 700;
  `,
  semibold: css`
    font-weight: 600;
  `,
  medium: css`
    font-weight: 500;
  `,
  regular: css`
    font-weight: 400;
  `,
}

const sizeCss: Record<ButtonLinkSize, FlattenSimpleInterpolation> = {
  1: css`
    ${textVariants['regular-2']}
    height: 20px;
    padding: 2px 0px;
    gap: 4px;
  `,
  2: css`
    ${textVariants['regular-3']}
    height: 20px;
    padding: 0px;
    gap: 6px;
  `,
  3: css`
    ${textVariants['regular-4']}
    height: 24px;
    padding: 0px;
    gap: 8px;
  `,
  4: css`
    ${textVariants['regular-5']}
    height: 28px;
    padding: 0px;
    gap: 8px;
  `,
}

const iconSize: Record<ButtonLinkSize, number> = {
  1: 16,
  2: 20,
  3: 24,
  4: 24,
}

const StyledButtonLink = styled.button<ButtonLinkProps>`
  display: flex;
  width: ${(p) => (p.fullWidth ? '100%' : 'fit-content')};
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;
  transition: 0.3s ease-out all;
  border: none;
  border-bottom: ${(p) => (p.underline ? '1px solid transparent' : 'none')};
  padding: 0;
  outline: none;
  background-color: transparent;
  ${(p) => sizeCss[p.size]}
  ${(p) => weightCss[p.weight]}
  ${(p) => highContrastCss[p.highContrast ? 1 : 0][p.colorTheme]}
`

export const ButtonLink = React.forwardRef<HTMLButtonElement, ButtonLinkProps>(
  (
    {
      weight,
      highContrast,
      size,
      underline,
      fullWidth,
      colorTheme,
      children,
      icon,
      startIcon: _startIcon,
      endIcon: _endIcon,
      type: buttonType = 'button',
      ...rest
    },
    ref,
  ) => {
    const { startIconName, endIconName, type } = icon || {
      startIcon: undefined,
      endIcon: undefined,
      type: undefined,
    }

    return (
      <StyledButtonLink
        ref={ref}
        weight={weight}
        size={size}
        highContrast={highContrast}
        fullWidth={fullWidth}
        colorTheme={colorTheme}
        underline={underline}
        type={buttonType}
        {...rest}
      >
        {/* For custom react node */}
        {_startIcon && _startIcon}
        <IconRenderer
          sizeMap={iconSize}
          size={size}
          iconType={type}
          iconName={startIconName}
        />
        {children}
        <IconRenderer
          sizeMap={iconSize}
          size={size}
          iconType={type}
          iconName={endIconName}
        />
        {/* For custom react node */}
        {_endIcon && _endIcon}
      </StyledButtonLink>
    )
  },
)

ButtonLink.displayName = 'ButtonLink'
