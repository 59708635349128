import { useCallback, useContext, useEffect, useState } from 'react'
import { IChatV2, GetChatsParams } from '@/models/IChatV2'
import { IUser } from '@/models/IUser'
import { IPagination } from '@/models/IPagination'
import { ReportReason } from '@/models/IChatV2'
import { PusherContext } from '@/contexts/Pusher'
import { useAuthContext } from '@/contexts/Auth'
import { useMessagesContext } from '@/contexts/Inbox/messages'
import useApi from './useApi'

const useInboxV2Chat = (chatId?: string) => {
  const { api, status } = useApi()
  const authContext = useAuthContext()
  const itemsLimit = 100
  const initialPagination = {
    totalPages: 0,
    page: 1,
    token: '',
    totalItems: 0,
    isLastPage: true,
  }

  const [pagination, setPagination] = useState<IPagination>(initialPagination)
  const [users, setUsers] = useState<IUser[]>([])
  const [searchText, setSearchText] = useState('')
  const [loading, setLoading] = useState(false)
  const [items, setItems] = useState<IChatV2[]>([])
  const [chats, setChats] = useState<IChatV2[]>([])
  const messagesContext = useMessagesContext()
  const { pusher } = useContext(PusherContext)

  const mapItems = useCallback(
    (itemsToMap: IChatV2[]) => {
      return itemsToMap.map((item) => ({
        ...item,
        participants:
          item.type === 'private'
            ? item.participants.filter(
                ({ userId }) => userId !== authContext.user?.id,
              )
            : item.participants,
      }))
    },
    [authContext.user?.id],
  )
  const getUsers = useCallback(
    async (params: {
      text?: string
      limit?: number
      excludeChatId?: string
    }) => {
      if (status === 'loading') return

      setLoading(true)
      const response = await api.inboxV2
        .getNewChatUsers({
          text: params.text?.trim(),
          limit: params.limit || 20,
          excludeChatId: params.excludeChatId,
        })
        .catch((error) => {
          console.error('Failed to fetch users:', error)
          setUsers([])
          setPagination(initialPagination)
          return null
        })

      if (!response) {
        setLoading(false)
        return
      }
      if (params.text) {
        setPagination({
          ...initialPagination,
          token: response.pagination?.token || '',
        })
      }

      setUsers(response.users || [])
      setPagination(response.pagination || initialPagination)
      setLoading(false)

      return response
    },
    [api, status],
  )

  const createChat = useCallback(
    async (fromChatId: string | undefined, participants: string[]) => {
      try {
        const body = {
          fromChatId,
          participants,
        }

        const chat = await api.inboxV2.createChat(body)
        return chat
      } catch (error) {
        console.error('Failed to create chat:', error)
        throw error
      }
    },
    [api, pusher],
  )

  const get = useCallback(
    async (status = '', limit?: number) => {
      setLoading(true)
      try {
        const response = await api.inboxV2.getChatsV2('', itemsLimit, status)
        if (!response) {
          console.log('Received undefined response')
          return
        }

        const { data } = response
        if (data.requests) {
          messagesContext.onChatRequest(data.requests)
        }
        setItems(mapItems(data.items))
        setPagination(data.pagination)
      } catch (error) {
        console.error('Error fetching chats:', error)
      }
      setLoading(false)
    },
    [itemsLimit, status],
  )

  const updateLocal = (id: string, value: Partial<IChatV2>) => {
    setItems((prev) =>
      mapItems(
        prev.map((item) => (item.id === id ? { ...item, ...value } : item)),
      ),
    )
  }

  const onChatUpdate = (id: string, value: IChatV2) => {
    setItems((prev) =>
      mapItems([
        {
          ...(prev.find((item) => item.id === id) || {}),
          ...value,
          unreadCount:
            value.lastMessageUser === authContext.user?.id
              ? 0
              : (prev.find((item) => item.id === id)?.unreadCount || 0) + 1,
        },
        ...prev.filter((item) => item.id !== id),
      ]),
    )
  }

  const onNewChat = (chat: IChatV2) => {
    setItems((prev) => [chat].concat(prev.filter(({ id }) => id !== chat.id)))
  }
  const onAcceptRequest = (chatId: string) => {
    const chat = items.find(({ id }) => id === chatId)
    setItems((prev) => prev.filter(({ id }) => id !== chatId))
    messagesContext.setRequests((prev) => ({
      ...(prev
        ? {
            count: prev.count - 1,
            title: prev.title,
          }
        : { count: 0, title: '' }),
    }))

    return chat
  }

  const removeChat = (chatId: string) => {
    const chat = items.find(({ id }) => id === chatId)
    setItems((prev) => prev.filter(({ id }) => id !== chatId))
    return chat
  }

  const archiveChat = useCallback(
    async (id: string) => {
      if (status === 'loading') return

      setLoading(true)

      const response = await api.inboxV2.archiveChat(id).catch((error) => {
        setLoading(false)
        throw error
      })

      setLoading(false)
      return response
    },
    [api, status],
  )

  const restoreChat = useCallback(
    async (chatId: string) => {
      if (status === 'loading') return
      setLoading(true)
      const response = await api.inboxV2
        .restoreArchivedChat(chatId)
        .catch((error) => {
          console.error('Failed to restore chat:', error)
          setLoading(false)
          throw error
        })
      setLoading(false)
      return response
    },
    [api, status],
  )

  const deleteChat = useCallback(
    async (chatId: string) => {
      if (status === 'loading') return
      setLoading(true)
      try {
        setChats((prevChats: IChatV2[]) => {
          const updatedChats = prevChats.filter((chat) => chat.id !== chatId)
          return updatedChats
        })
        await api.inboxV2.deleteArchivedChat(chatId)
      } catch (error) {
        console.error('Failed to delete chat:', error)
      } finally {
        setLoading(false)
      }
    },
    [api, status],
  )
  const reportOwner = useCallback(
    async (chatId: string, reason: string) => {
      if (status === 'loading') return
      setLoading(true)
      const response = await api.inboxV2
        .reportChatOwner(chatId, reason as unknown as ReportReason)
        .catch((error) => {
          console.error('Failed to report chat owner:', error)
          setLoading(false)
          throw error
        })
      setLoading(false)
      return response
    },
    [api, status],
  )

  const reportMessage = useCallback(
    async (id: string, reason: string) => {
      if (status === 'loading') return
      setLoading(true)
      const response = await api.inboxV2
        .reportMessage(id, reason as unknown as ReportReason)
        .catch((error) => {
          console.error('Failed to report chat owner:', error)
          setLoading(false)
          throw error
        })
      setLoading(false)
      return response
    },
    [api, status],
  )

  const blockOwner = useCallback(
    async (chatId: string) => {
      if (status === 'loading') return
      setLoading(true)
      const response = await api.inboxV2
        .blockChatOwner(chatId)
        .catch((error) => {
          console.error('Failed to block chat owner:', error)
          setLoading(false)
          throw error
        })
      setLoading(false)
      return response
    },
    [api, status],
  )

  const removeParticipant = useCallback(
    async (chatId: string, participantId: string) => {
      if (loading) return false

      setLoading(true)

      try {
        const response = await api.inboxV2.removeParticipant(
          chatId,
          participantId,
        )
        setLoading(false)
        return response.success
      } catch (error) {
        throw error
      }
    },
    [api, loading],
  )

  const deleteImage = useCallback(
    async (chatId: string) => {
      if (loading) return false
      setLoading(true)
      try {
        const response = await api.inboxV2.deleteImage(chatId)
        setLoading(false)
        return response.success
      } catch (error) {
        setLoading(false)
        throw error
      }
    },
    [api, loading],
  )

  return {
    loading: status === 'loading' || loading,
    users,
    chats,
    archiveChat,
    getUsers,
    restoreChat,
    createChat,
    deleteChat,
    reportOwner,
    reportMessage,
    blockOwner,
    removeParticipant,
    deleteImage,
    searchText,
    items,
    pagination,
    get,
    setItems,
    onNewChat,
    updateLocal,
    onChatUpdate,
    removeChat,
    onAcceptRequest,
  }
}

export default useInboxV2Chat
