import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { Popup } from '@/components/uiJar/popup'
import { PaymentPopup } from '@/components/ui'
import { WizardPaymentIntent } from '@/utils/Api/payments.service'
import { PaymentReason } from '@/constants/payments'
import { useWizardContext } from '@/contexts/Wizard'
import { IProposal, ProposalStatus } from '@/models/IProposal'
import { IUserVisaWizardPayment } from '@/models/IUserVisaWizardPayment'
import getProposalStatus from '@/contexts/Wizard/getProposalStatus'
import { ViewProposalDetail } from '../Popups/ViewProposals/ViewProposalDetail'
import { CongratulationsPopup } from '../Popups'
import ViewAllProposals from '../Popups/ViewProposals/ViewAllProposals'

export const ViewProposalFlow = forwardRef<{ trigger: () => void }>(
  ({}, ref) => {
    const { mode, triggerEditProposal, proposals } = useWizardContext()
    const [isTriggered, setTriggered] = useState(false)
    const [step, setStep] = useState(0)
    const [paymentId, setPaymentId] = useState<string>()
    const [selectedProposal, setSelectedProposal] = useState<IProposal | null>()

    const handleClose = () => {
      setTriggered(false)
      setStep(0)
    }

    const onProposalSelect = (selectedProposal?: IProposal) => {
      const proposalStatus = getProposalStatus(selectedProposal)
      const canEditProposal =
        proposalStatus === ProposalStatus.Sent ||
        proposalStatus === ProposalStatus.Updated
      if (mode === 'advisor' && canEditProposal) {
        triggerEditProposal(selectedProposal?.id as string)
      } else {
        setSelectedProposal(selectedProposal)
        setStep(1)
      }
    }

    const onGoBack = () => {
      setStep(0)
    }

    const returnUrl = `${
      new URL(location.pathname, location.href).href
    }?flow=payment-${paymentId}`

    useImperativeHandle(
      ref,
      () => {
        return {
          trigger: () => setTriggered(true),
        }
      },
      [],
    )

    if (!isTriggered) return null

    return (
      <>
        <Popup fullScreenInMobile isOpen={step === 0}>
          <ViewAllProposals
            onProposalSelect={onProposalSelect}
            onClose={handleClose}
            proposals={proposals || []}
          />
        </Popup>
        <Popup fullScreenInMobile isOpen={step === 1}>
          <ViewProposalDetail
            onClose={handleClose}
            proposal={selectedProposal}
            onPayment={(data: IUserVisaWizardPayment) => {
              setPaymentId(data.id)
              setStep(2)
            }}
            onGoBack={onGoBack}
          />
        </Popup>
        <Popup isOpen={step === 2}>
          {paymentId && (
            <PaymentPopup<WizardPaymentIntent>
              type={PaymentReason.WizardPayment}
              wizardPaymentId={paymentId}
              returnUrl={returnUrl}
              onSuccess={() => {
                setStep(4)
              }}
              onClose={() => {
                setPaymentId(undefined)
                setTriggered(false)
                setStep(0)
              }}
            />
          )}
        </Popup>

        <Popup isOpen={step === 3}>
          <CongratulationsPopup
            title={`Congratulations`}
            message="Your payment has been received"
            onClose={() => {
              window.location.href = `${
                new URL(location.pathname, location.href).href
              }`
            }}
          />
        </Popup>
      </>
    )
  },
)

ViewProposalFlow.displayName = 'ViewProposalFlow'
