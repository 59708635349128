import { AxiosInstance } from 'axios'
import { IPagination } from '@/models/IPagination'
import { IUserVisaWizard } from '@/models/IUserVisaWizard'
import { IUserVisaWizardNote } from '@/models/IUserVisaWizardNote'
import { IVisaWizardAnswer } from '@/models/IVisaWizardAnswer'
import { IFileRef } from '@/models/IFile'
import { IAdvisor } from '@/models/IAdvisor'
import { IUserVisaWizardPayment } from '@/models/IUserVisaWizardPayment'
import { IAdvisorFileTemplate } from '@/models/IAdvisorFileTemplate'
import { WizardStatus } from '@/constants/wizard'
import { IProposal, Prices } from '@/models/IProposal'
import { IFolder } from '@/models/IFolder'
import { CalendarType, ICalendar } from '@/models/ICalendar'
import { IAdvisorBooking } from '@/models/IAdvisorBooking'
import { AdvisorEventType } from '@/models/IAdvisorEvent'
import { OutstandingProposalsResponse } from '@/components/dashboard/types'
import { UserWizardResponse } from './wizard.service'

interface GetCasesRequest {
  requestStatus: 'pending' | 'accepted' | 'archived'
  limit?: number
}

interface RequestDocument {
  name: string
  notes?: string
  file?: IFileRef
  save: boolean
  addAutomatically: boolean
}

interface ProposalResponse {
  proposal: IProposal
  initialPayment: IUserVisaWizardPayment
}

export interface CreateProposalRequest {
  userWizardId?: string
  summary?: string
  initialPayment?: number
  prices: Prices[]
}

export interface AdvisorDocumentsResponse {
  files: IAdvisorFileTemplate[]
  folders: IFolder[]
}

interface createFolderRequest {
  title: string
}

export interface CreateTemplateRequest {
  name: string
  notes?: string
  file?: IFileRef
  folderId?: string
}

export interface UpdateTemplateRequest {
  name?: string
  notes?: string
  file?: IFileRef
  folderId?: string
}

interface AddExistingFilesRequest {
  templateIds: string[]
}

interface RequestDocumentPayload {
  addedTemplates?: string[]
  removedTemplates?: (string | undefined)[]
}
export interface UpdateEventTypePayload {
  price?: number
  length?: number
}
export interface UpdateSchedulePayload {
  Monday?: [string, string]
  Tuesday?: [string, string]
  Wednesday?: [string, string]
  Thursday?: [string, string]
  Friday?: [string, string]
  Saturday?: [string, string]
  Sunday?: [string, string]
  overrides: {
    date: string
    startTime: string
    endTime: string
  }[]
}

export class AdvisorService {
  constructor(private instance: AxiosInstance) {}

  profile = () =>
    this.instance.get<IAdvisor>('/advisor').then((response) => response.data)

  update = (body: Partial<IAdvisor>) =>
    this.instance.patch('/advisor', body).then((response) => response.data)

  getCases = (params: GetCasesRequest) => {
    return this.instance
      .get<IPagination & { userWizards: IUserVisaWizard[] }>(`/advisor/cases`, {
        params,
      })
      .then((response) => response.data)
  }

  getCase = (id: string) => {
    return this.instance
      .get<UserWizardResponse>(`/advisor/case/${id}`)
      .then((response) => response.data)
  }

  addNote = (id: string, note: string) => {
    return this.instance
      .post<IUserVisaWizardNote>(`/advisor/case/${id}/notes`, { note })
      .then((response) => response.data)
  }

  getAllNotes = (id: string) => {
    return this.instance
      .get<IUserVisaWizardNote[]>(`/advisor/case/${id}/notes`)
      .then((response) => response.data)
  }

  deleteNote = (id: string, noteId: string) => {
    return this.instance
      .delete(`/advisor/case/${id}/notes/${noteId}`)
      .then((response) => response.data)
  }

  acceptCase = (id: string, deadline?: string) => {
    return this.instance
      .patch<IUserVisaWizard>(`/advisor/case/${id}/accept`, { deadline })
      .then((response) => response.data)
  }

  requestDocument = (id: string, body: RequestDocument) => {
    return this.instance
      .post<IVisaWizardAnswer>(`/advisor/case/${id}/request-document`, body)
      .then((response) => response.data)
  }

  updateDeadline = (id: string, deadline?: string) => {
    return this.instance
      .patch<IUserVisaWizard>(`/advisor/case/${id}/deadline`, { deadline })
      .then((response) => response.data)
  }

  updateStatus = (id: string, status: WizardStatus, reason?: string) =>
    this.instance
      .patch<IUserVisaWizard>(`/advisor/case/${id}/status`, { status, reason })
      .then((response) => response.data)

  uploadFile = (data: FormData) => {
    return this.instance
      .post<IFileRef>('/advisor/file', data)
      .then((response) => response.data)
  }

  stripeDashboard = () =>
    this.instance
      .post<string>('/advisor/stripe-dashboard')
      .then((response) => response.data)

  requestPayment = (
    id: string,
    amount: number,
    reason: string,
    proposalId?: string,
  ) =>
    this.instance.post(`/advisor/case/${id}/payment`, {
      amount,
      reason,
      proposalId,
    })

  getPayments = (id: string) =>
    this.instance
      .get<IPagination & { payments: IUserVisaWizardPayment[] }>(
        `/advisor/case/${id}/payments`,
      )
      .then((response) => response.data)

  deletePayment = (paymentId: string, userWizardId: string) =>
    this.instance.delete(`advisor/case/${userWizardId}/payments/${paymentId}`)

  getTemplates = () =>
    this.instance
      .get<IAdvisorFileTemplate[]>(`/advisor/templates`)
      .then((response) => response.data)

  createProposal = (body: CreateProposalRequest) =>
    this.instance
      .post<ProposalResponse>('/advisor/create-proposal', body)
      .then((response) => response.data)

  advisorDocuments = () =>
    this.instance
      .get<AdvisorDocumentsResponse>('/advisor/advisor-documents')
      .then((response) => response.data)

  createFolder = (body: createFolderRequest) =>
    this.instance
      .post<IFolder>('/advisor/create-folder', body)
      .then((response) => response.data)

  deleteFolder = (id: string) => this.instance.delete(`/advisor/folder/${id}`)

  updateFolder = (id: string, body: createFolderRequest) =>
    this.instance
      .patch<IFolder>(`/advisor/folder/${id}`, body)
      .then((response) => response.data)

  createTemplate = (body: CreateTemplateRequest) =>
    this.instance
      .post<IAdvisorFileTemplate>('/advisor/create-template', body)
      .then((response) => response.data)

  updateTemplate = (id: string, body: UpdateTemplateRequest) =>
    this.instance
      .patch<IAdvisorFileTemplate>(`/advisor/update-template/${id}`, body)
      .then((response) => response.data)

  addExistingFiles = (folderId: string, body: AddExistingFilesRequest) =>
    this.instance
      .patch<IAdvisorFileTemplate[]>(
        `/advisor/update-existing-templates/${folderId}`,
        body,
      )
      .then((response) => response.data)

  deleteTemplate = (id: string) =>
    this.instance.delete(`/advisor/remove-template/${id}`)

  requestDocuments = (id: string, body: RequestDocumentPayload) =>
    this.instance
      .post<IVisaWizardAnswer[]>(`/advisor/case/${id}/request-documents`, body)
      .then((response) => response.data)

  archiveCase = (id: string, archived: boolean, archiveReason?: string) =>
    this.instance.patch(`/advisor/archive-case/${id}`, {
      archived,
      archiveReason,
    })

  getCalendars = () =>
    this.instance
      .get<ICalendar>('/advisor/calendars')
      .then((response) => response.data)

  deleteCalendar = (type: CalendarType, credentialId: number) =>
    this.instance
      .delete(`/advisor/calendars/${type}/${credentialId}`)
      .then((response) => response.data)

  getEventTypes = () =>
    this.instance.get('/advisor/event-types').then((response) => response.data)

  getSchedule = () =>
    this.instance.get('/advisor/schedule').then((response) => response.data)

  getBookings = () =>
    this.instance
      .get<IAdvisorBooking>('/wizard/booking/all')
      .then((response) => response.data)

  updateSchedule = (data: UpdateSchedulePayload) =>
    this.instance
      .put<UpdateSchedulePayload>('/advisor/schedule', data)
      .then((response) => response.data)

  updateEventType = (type: AdvisorEventType, data: UpdateEventTypePayload) =>
    this.instance
      .put(`/advisor/event-types/${type}`, data)
      .then((response) => response.data)

  getProposals = () =>
    this.instance
      .get<OutstandingProposalsResponse>('/advisor/proposals')
      .then((response) => response.data)
}
