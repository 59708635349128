import React, { useState, forwardRef, useImperativeHandle } from 'react'
import { IProposal } from '@/models/IProposal'
import { Popup } from '@/components/uiJar/popup'
import { WithdrawProposalPopup } from '../Popups/WithdrawProposal'

export const WithdrawProposal = forwardRef<
  { trigger: (proposalId: string) => void },
  {
    onUpdateProposals: (proposal: IProposal) => void
  }
>(({ onUpdateProposals }, ref) => {
  const [id, setId] = useState<string>('')
  const [isTriggered, setTriggered] = useState(false)

  useImperativeHandle(
    ref,
    () => {
      return {
        trigger: (proposalId: string) => {
          setTriggered(true)
          setId(proposalId)
        },
      }
    },
    [],
  )

  if (!isTriggered) return null

  return (
    <>
      <Popup isOpen={true}>
        <WithdrawProposalPopup
          onCancel={() => setTriggered(false)}
          proposalId={id}
          onUpdate={(proposal: IProposal) => onUpdateProposals(proposal)}
        />
      </Popup>
    </>
  )
})

WithdrawProposal.displayName = 'WithdrawProposal'
