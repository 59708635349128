export enum PaymentReason {
  Esim = 'e-sim',
  EsimTopUp = 'e-sim-top-up',
  Advisor = 'advisor',
  Consultation = 'consultation',
  WizardPayment = 'wizardPayment',
  DocumentCertification = 'documentCertification',
  PremiumSubscription = 'premiumSubscription',
  CalComBooking = 'calComBooking',
}
