import { BookingResponse } from '@calcom/atoms/dist/packages/features/bookings/types'
import { ApiSuccessResponse } from '@calcom/atoms/dist/packages/platform/types'
import { IUser } from '@/models/IUser'
import Api from './Api'

export default async function updateMissingNameFromBooking(
  user: IUser,
  booking: ApiSuccessResponse<BookingResponse>,
  api: Api,
) {
  if (user.firstName) return
  const { data } = booking
  const name = data.attendees?.length ? data.attendees[0].name : ''
  if (!name) return
  const [firstName, lastName] = name.split(' ')
  await api.auth.updateProfile({
    firstName,
    lastName,
  })
}
