import React, { forwardRef, useImperativeHandle, useState } from 'react'

import { IUser } from '@/models/IUser'
import { Popup } from '@/components/uiJar/popup'
import getAdvisorTitle from '@/utils/getAdvisorTitle'
import { useWizardContext } from '@/contexts/Wizard'
import { CongratulationsPopup, HireAdvisorPopup } from '../Popups'

export const HireAdvisorFlow = forwardRef<
  { trigger: () => void },
  { advisor: IUser }
>(({ advisor }, ref) => {
  const [isTriggered, setTriggered] = useState(false)
  const { userWizard } = useWizardContext()
  const [step, setStep] = useState(0)

  const handleClose = () => {
    setTriggered(false)
    setStep(0)
  }

  useImperativeHandle(
    ref,
    () => {
      return {
        trigger: () => setTriggered(true),
      }
    },
    [],
  )

  if (!isTriggered) return null

  return (
    <>
      <Popup fullScreenInMobile isOpen={step === 0}>
        <HireAdvisorPopup
          advisor={advisor}
          onClose={handleClose}
          onConfirm={() => setStep(1)}
        />
      </Popup>
      <Popup fullScreenInMobile isOpen={step === 1}>
        <CongratulationsPopup
          hideReceipt
          topBarTitle="Request Proposal"
          title={`Woo hoo! Your request has been sent.`}
          message={`You will receive an email notification once your ${getAdvisorTitle(
            userWizard.country?.isoCode,
          )} has sent over their proposal.`}
          onClose={handleClose}
        />
      </Popup>
    </>
  )
})

HireAdvisorFlow.displayName = 'HireAdvisorFlow'
