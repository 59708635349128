import React from 'react'
import ReactMarkdown from 'react-markdown'
import { Flex, Icon, Text } from '@/components/ui'
import {
  DefaultPopupFooter,
  FullScreenMobileContainer,
} from '@/components/uiJar/utilityStyles'
import { Button } from '@/components/uiJar/Button'
import { ConfirmProposalPaymentMode } from '@/components/myCases/RequestPayment/types'
import { Note, IconWrapper, PopupContentWrapper } from './styles'

interface ProposalSentPopupProps {
  title: string
  message: string
  noteTitle: string
  note: string
  onClose: () => void
  mode: ConfirmProposalPaymentMode
}

export const ProposalSentPopup = ({
  title,
  message,
  noteTitle,
  note,
  onClose,
  mode,
}: ProposalSentPopupProps) => {
  const handleContinue = () => {
    onClose()
  }
  return (
    <FullScreenMobileContainer>
      <PopupContentWrapper>
        <Flex direction="column" gap={20}>
          <IconWrapper>
            <Icon.Solid name="circleCheck" size={48} />
          </IconWrapper>
          <Flex gap={4} direction="column">
            <Text variant="semibold-4">{title}</Text>
            <Text variant="regular-3" colorVar="neutral-11">
              {message && <ReactMarkdown>{message}</ReactMarkdown>}
            </Text>
          </Flex>

          {mode === 'request' && (
            <Note direction="column" gap={8}>
              <Icon.Solid size={24} name="circleInfo" />
              <Flex direction="column" gap={2}>
                <Text variant="medium-3">{noteTitle}</Text>
                <Text variant="regular-3">{note}</Text>
              </Flex>
            </Note>
          )}
        </Flex>
      </PopupContentWrapper>
      <DefaultPopupFooter>
        <Button
          fullWidth
          size={3}
          variant="solid"
          colorTheme="neutral"
          onClick={handleContinue}
        >
          Continue
        </Button>
      </DefaultPopupFooter>
    </FullScreenMobileContainer>
  )
}
