import styled, { css } from 'styled-components'
import { SwitchSize } from '.'

const getContainerSizeCss = (size: SwitchSize) => {
  switch (size) {
    case 1:
      return css`
        width: 28px;
        height: 16px;
      `
    case 2:
      return css`
        width: 36px;
        height: 20px;
      `
    case 3:
      return css`
        width: 40px;
        height: 24px;
      `
  }
}

const getCircleCss = (size: SwitchSize, isActive?: boolean) => {
  switch (size) {
    case 1:
      return css`
        width: 12px;
        height: 12px;
        left: ${isActive ? '14px' : '2px'};
      `
    case 2:
      return css`
        width: 16px;
        height: 16px;
        left: ${isActive ? '18px' : '2px'};
      `
    case 3:
      return css`
        width: 20px;
        height: 20px;
        left: ${isActive ? '18px' : '2px'};
      `
  }
}

export const SwtichButton = styled.button<{
  $size: SwitchSize
  $isActive?: boolean
  disabled?: boolean
}>`
  flex-shrink: 0;
  transition: 1s ease-out all;
  cursor: pointer;
  border: ${(p) => (p.disabled ? '1px solid var(--neutral-alpha-4)' : 'none')};
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 1000px;
  ${(p) => getContainerSizeCss(p.$size)}
  background: ${(p) =>
    p.disabled
      ? 'var(--panel-3) !important'
      : p.$isActive
      ? 'var(--success-9)'
      : 'var(--neutral-alpha-5)'};
  &:hover {
    background: ${(p) =>
      p.$isActive ? 'var(--success-10)' : 'var(--neutral-alpha-7)'};
  }
  &::before {
    transition: 0.2s ease-out all;
    content: '';
    position: absolute;
    top: 0;
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
    background: ${(p) => (p.disabled ? 'var(--neutral-alpha-5)' : 'white')};
    ${(p) => getCircleCss(p.$size, p.$isActive)}
  }
`
