import {
  ComputedProposalStatus,
  IProposal,
  UProposalStatus,
} from '@/models/IProposal'

export default function getProposalStatus(
  proposal?: IProposal | null,
  advisorRequestedAt?: string,
): UProposalStatus {
  if (proposal) {
    return proposal.status
  }
  if (advisorRequestedAt) {
    return ComputedProposalStatus.Requested
  }
  return ComputedProposalStatus.Default
}

export function proposalRequested(
  proposals?: IProposal[] | null,
  advisorRequestedAt?: string,
): boolean {
  return !proposals || !proposals.length
    ? getProposalStatus(null, advisorRequestedAt) ===
        ComputedProposalStatus.Requested
    : false
}
