import '@/styles/globals.scss'
import 'react-toastify/dist/ReactToastify.css'
import '@calcom/atoms/globals.min.css'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import Script from 'next/script'
import { ToastContainer } from 'react-toastify'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { QuizProvider } from '@/contexts/Quiz'
import { AuthProvider } from '@/contexts/Auth'
import { MessagesProvider } from '@/contexts/Inbox'
import { PusherProvider } from '@/contexts/Pusher'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { inter, interDisplay } from '@/fonts/fonts'
import { LayoutSearchProvider } from '@/contexts/LayoutSearch'
import { UIStateProvider } from '@/contexts/UIState'
import { ThemeProvider } from '@/contexts/Theme'
import { PremiumSubscriptionProvider } from '@/contexts/PremiumSubscription'

const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_KEY || '')

export default function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,maximum-scale=1,user-scalable=0"
        />
      </Head>
      {/* id is set so that portals can reference this item instead of document.body */}
      <main id="main" className={interDisplay.variable + ' ' + inter.variable}>
        <Script
          src={`https://sdk.joinsherpa.io/widget.js?appId=${process.env.NEXT_PUBLIC_SHERPA_APP_KEY}`}
          strategy="lazyOnload"
          onError={(e) => {
            console.error('Error loading Sherpa SDK:', e)
          }}
        />
        <UIStateProvider>
          <ThemeProvider>
            <AuthProvider>
              <LayoutSearchProvider>
                <QuizProvider>
                  <MessagesProvider>
                    <PusherProvider>
                      <Elements stripe={stripePromise}>
                        <PremiumSubscriptionProvider>
                          <Component {...pageProps} />
                        </PremiumSubscriptionProvider>
                      </Elements>
                    </PusherProvider>
                  </MessagesProvider>
                </QuizProvider>
              </LayoutSearchProvider>
            </AuthProvider>
          </ThemeProvider>
        </UIStateProvider>
      </main>
      <ToastContainer />
    </>
  )
}
