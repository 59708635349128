export default function getAdvisorTitle(
  isoCode?: string,
  variant: 'short' | 'long' = 'long',
) {
  // Use Immigration Advisor for France and Germany
  if (isoCode === 'FRA' || isoCode === 'DEU') {
    if (variant === 'short') return 'advisor'
    return 'Immigration Advisor'
  }
  if (variant === 'short') return 'lawyer'
  return 'Immigration Lawyer'
}
