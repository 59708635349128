import React from 'react'
import { PopupTopBar } from '@/components/uiJar/popup'
import {
  DefaultPopupFooter,
  FullScreenMobileContainer,
  FullScreenPopupContentWrapper,
} from '@/components/uiJar/utilityStyles'
import { Flex } from '@/components/ui'
import { Button } from '@/components/uiJar/Button'
import { IProposal } from '@/models/IProposal'
import { useWizardContext } from '@/contexts/Wizard'
import ProposalCard from './ProposalCard'

type ViewAllProposalsProps = {
  onClose: () => void
  onProposalSelect: (proposal?: IProposal) => void
  proposals: IProposal[]
}

const ViewAllProposals: React.FC<ViewAllProposalsProps> = ({
  onClose,
  onProposalSelect,
  proposals,
}) => {
  const { mode, triggerCreateProposal } = useWizardContext()
  return (
    <FullScreenMobileContainer>
      <PopupTopBar onClose={onClose}>View proposal</PopupTopBar>
      <FullScreenPopupContentWrapper>
        <Flex direction="column" gap={16}>
          {proposals.map((proposal, index) => (
            <ProposalCard
              index={proposals.length - index}
              key={proposal.id}
              proposal={proposal}
              onSelect={() => onProposalSelect(proposal)}
            />
          ))}
        </Flex>
      </FullScreenPopupContentWrapper>
      <DefaultPopupFooter>
        {mode !== 'advisor' ? (
          <Button
            onClick={onClose}
            fullWidth
            size={3}
            variant="solid"
            colorTheme="neutral"
          >
            Close
          </Button>
        ) : (
          <Button
            onClick={() => {
              triggerCreateProposal()
              onClose()
            }}
            fullWidth
            size={3}
            variant="solid"
            colorTheme="neutral"
          >
            Create new proposal
          </Button>
        )}
      </DefaultPopupFooter>
    </FullScreenMobileContainer>
  )
}

export default ViewAllProposals
