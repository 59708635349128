import React, { type InputHTMLAttributes, type PropsWithChildren } from 'react'
import styled, { css } from 'styled-components'
import { textVariants } from '@/components/ui/Text'
import { Icon } from '@/components/ui/Icon'

type CheckboxSize = 1 | 2 | 3

interface Props
  extends InputHTMLAttributes<HTMLInputElement>,
    PropsWithChildren {
  isRound?: boolean
  isDark?: boolean
  isContentLeft?: boolean
  iconVariant?: 'minus' | 'check'
  size?: CheckboxSize
}

const sizeStyles = {
  1: css`
    height: 16px;
    width: 16px;
    i {
      width: 14px;
      height: 14px;
    }
  `,
  2: css`
    height: 20px;
    width: 20px;
    i {
      width: 16px;
      height: 16px;
    }
  `,
  3: css`
    height: 24px;
    width: 24px;
    i {
      width: 18px;
      height: 18px;
    }
  `,
}

const Control = styled.span<{
  isRound?: boolean
  isDark?: boolean
  hasContent: boolean
  size: CheckboxSize
  disabled?: boolean
}>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-shrink: 0;
  ${({ size }) => sizeStyles[size]}
  border: 1px solid var(--neutral-alpha-6);
  transition: 0.1s all;
  border-radius: ${(p) =>
    p.isRound
      ? '999px'
      : p.isDark
      ? '4px'
      : p.size === 1
      ? '4px'
      : p.size === 2
      ? '6px'
      : '8px'};
  margin-right: ${(p) => (p.hasContent ? '12px' : '0px')};
`
const IconWrapper = styled.div`
  i {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--neutral-1) !important;
  }
`
const Container = styled.label`
  ${textVariants['body-m-regular']}
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  &:hover input ~ ${Control} {
    border-color: var(--neutral-alpha-7);
    background: var(--neutral-alpha-2);
  }
  input:checked ~ ${Control} {
    background-color: var(--neutral-12);
    border: none;
    &:hover {
      opacity: 0.8;
    }
  }
  input:disabled + ${Control} {
    background-color: var(--panel-3);
    border: 1px solid var(--neutral-alpha-4);
    & i {
      color: var(--neutral-alpha-8) !important;
    }
  }
`

const Input = styled.input`
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
`

export const Component = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      isRound,
      isDark,
      isContentLeft,
      iconVariant = 'check',
      size = 2,
      children,
      className,
      ...rest
    },
    ref,
  ): JSX.Element => {
    return (
      <Container as={'label'} className={className}>
        {isContentLeft && children}
        <Input type="checkbox" ref={ref} {...rest} />
        <Control
          disabled={rest.disabled}
          isRound={isRound}
          isDark={isDark}
          hasContent={!!children}
          size={size}
        >
          {rest.checked && (
            <IconWrapper>
              {iconVariant === 'minus' ? (
                <Icon.Outline id="checkbox-icon" name="minus" />
              ) : (
                <Icon.Outline id="checkbox-icon" name="_Check" />
              )}
            </IconWrapper>
          )}
        </Control>
        {!isContentLeft && children}
      </Container>
    )
  },
)

Component.displayName = 'Checkbox'

export const Checkbox = React.memo(Component)
