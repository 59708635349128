import axios, { AxiosInstance, AxiosResponse, AxiosRequestConfig } from 'axios'
import { AuthService } from './auth.service'
import { LocationService } from './location.service'
import { ExploreService } from './explore.service'
import { ConnectService } from './connect.service'
import { SocialService } from './social.service'
import { UtilsService } from './utils.service'
import { WizardService } from './wizard.service'
import { PaymentsService } from './payments.service'
import { AdvisorService } from './advisor.service'
import { InboxService } from './inbox.service'
import { InboxV2Service } from './inboxV2.service'
import { InsuranceService } from './insurance.service'
import { VisaService } from './visa.service'
import { SherpaService } from './sherpa.service'
import { SettingsService } from './settings.service'
import { CalService } from './cal.service'
export default class Api {
  client: AxiosInstance
  auth: AuthService
  location: LocationService
  explore: ExploreService
  connect: ConnectService
  social: SocialService
  utils: UtilsService
  wizard: WizardService
  payments: PaymentsService
  advisor: AdvisorService
  inbox: InboxService
  inboxV2: InboxV2Service
  insurance: InsuranceService
  visa: VisaService
  sherpa: SherpaService
  settings: SettingsService
  cal: CalService

  constructor() {
    // build client
    this.client = axios.create({
      baseURL: process.env.NEXT_PUBLIC_API_BASE,
    })

    // initialize service classes
    this.auth = new AuthService(this.client)
    this.location = new LocationService(this.client)
    this.explore = new ExploreService(this.client)
    this.connect = new ConnectService(this.client)
    this.social = new SocialService(this.client)
    this.utils = new UtilsService(this.client)
    this.wizard = new WizardService(this.client)
    this.payments = new PaymentsService(this.client)
    this.advisor = new AdvisorService(this.client)
    this.inbox = new InboxService(this.client)
    this.inboxV2 = new InboxV2Service(this.client)
    this.insurance = new InsuranceService(this.client)
    this.visa = new VisaService(this.client)
    this.sherpa = new SherpaService(this.client)
    this.settings = new SettingsService(this.client)
    this.cal = new CalService(this.client)
  }

  // for those scenarios where token is not available in AuthContext like session resumption
  authorize(token: string) {
    this.client.defaults.headers['Authorization'] = `Bearer ${token}`
  }
}
