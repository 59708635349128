import styled, { css, FlattenSimpleInterpolation } from 'styled-components'
import { textVariants } from '@/components/ui/Text'
import { InputSize } from '../InputField'
import { IconHolderProps, InputFieldProps, inputHorizontalIconOffset } from '.'

export function getSizeCss(
  hasStartIcon = false,
  hasEndIcon = false,
): Record<InputSize, FlattenSimpleInterpolation> {
  return {
    1: css`
      ${textVariants['regular-3']}
      height: 32px;
      padding-left: ${hasStartIcon ? '32px' : '8px'};
      padding-right: ${hasEndIcon ? '32px' : '8px'};
      border-radius: 6px;
      &::placeholder {
        ${textVariants['regular-3']}
      }
    `,
    2: css`
      ${textVariants['regular-3']}
      height: 40px;
      padding-left: ${hasStartIcon ? '38px' : '10px'};
      padding-right: ${hasEndIcon ? '38px' : '10px'};
      border-radius: 8px;
      &::placeholder {
        ${textVariants['regular-3']}
      }
    `,
    3: css`
      ${textVariants['regular-4']}
      height: 48px;
      padding-left: ${hasStartIcon ? '44px' : '12px'};
      padding-right: ${hasEndIcon ? '44px' : '12px'};
      border-radius: 10px;
      &::placeholder {
        ${textVariants['regular-4']}
      }
    `,
    4: css`
      ${textVariants['regular-4']}
      height: 56px;
      padding-left: ${hasStartIcon ? '48px' : '16px'};
      padding-right: ${hasEndIcon ? '48px' : '16px'};
      border-radius: 12px;
      &::placeholder {
        ${textVariants['regular-4']}
      }
    `,
  }
}

function getOutlineCss(hasError = false) {
  return css`
    color: var(--neutral-alpha-12);
    background-color: var(--panel-1);
    border: 1px solid
      ${hasError ? 'var(--danger-11)' : 'var(--neutral-alpha-6)'};
    box-shadow: ${hasError ? '0px 0px 0px 2px rgba(255,0,0,0.1)' : 'none'};
    &:hover {
      background-color: var(--panel-3);
    }
    &::placeholder {
      color: var(--neutral-alpha-11);
    }
    &:focus {
      outline: none;
      border-color: ${hasError ? 'var(--danger-11)' : 'var(--neutral-12)'};
      box-shadow: 0px 0px 0px 2px
        ${hasError ? 'rgba(255,0,0,0.1)' : 'rgba(2,2,52,0.08)'};
    }
  `
}

function getSoftCss(hasError = false) {
  return css`
    color: var(--neutral-alpha-11);
    background-color: ${hasError
      ? 'var(--danger-alpha-3)'
      : 'var(--neutral-alpha-3)'};
    border: 1px solid transparent;
    &:hover {
      ${!hasError && 'background-color: var(--neutral-alpha-4);'}
    }
    &::placeholder {
      color: var(--neutral-alpha-11);
    }
    &:focus {
      outline: none;
      color: var(--neutral-12);
      background-color: transparent;
      border-color: var(--neutral-alpha-6);
      &::placeholder {
        color: var(--neutral-12);
      }
    }
  `
}

function getVariantCss(variant: 'outline' | 'soft', hasError = false) {
  return {
    outline: getOutlineCss(hasError),
    soft: getSoftCss(hasError),
  }[variant]
}

export const StyledInputField = styled.input<InputFieldProps>`
  width: 100%;
  display: ${(p) => (p.type === 'date' ? 'block' : 'flex')};
  position: relative;
  align-items: center;
  text-overflow: ellipsis;
  ${(p) => getSizeCss(!!p.startIcon, !!p.endIcon)[p.size || 2]}
  ${(p) => getVariantCss(p.variant || 'outline', p.hasError)}
`

export const StyledIconHolder = styled.div<
  IconHolderProps & { clickable?: boolean }
>`
  cursor: ${(p) => (p.clickable ? 'pointer' : 'default')};
  pointer-events: ${(p) => (p.clickable ? 'auto' : 'none')};
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: ${(p) =>
    p.focused ? 'var(--neutral-alpha-11)' : 'var(--neutral-alpha-9)'};
  z-index: 2;

  ${(p) =>
    p.orientation === 'start'
      ? css`
          left: ${inputHorizontalIconOffset[p.size || 2]}px;
        `
      : css`
          right: ${inputHorizontalIconOffset[p.size || 2]}px;
        `}
`

export const InputPrefixHolder = styled.div<{ orientation: 'left' | 'right' }>`
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 2;
  ${(p) => (p.orientation === 'left' ? 'left: 0' : 'right: 0')};
`

export const InputWrapper = styled.div`
  position: relative;
  &:hover {
    svg {
      color: var(--neutral-alpha-11);
    }
  }
`
export const InputFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const Label = styled.label`
  color: var(--neutral-12);
  ${textVariants['medium-3']}
  margin-bottom: 8px;
`
export const OptionalLabel = styled.span`
  margin-left: 1px;
  font-weight: 500;
  color: var(--neutral-11);
`
export const RequiredStar = styled.span`
  color: var(--primary-11);
  margin-left: 2px;
  font-weight: 500;
`
export const HelperTextWrapper = styled.div<{ hasError: boolean }>`
  display: flex;
  align-items: center;
  margin-top: 8px;
  color: ${(p) => (p.hasError ? 'var(--danger-11)' : 'var(--neutral-11)')};
`
export const HelperText = styled.span`
  ${textVariants['regular-2']}
  margin-left: 4px;
`
const getInputPrefixSizeCss = (size: InputSize) => {
  return {
    1: css`
      padding: 0 8px;
      height: 32px;
      gap: 4px;
      span {
        ${textVariants['medium-2']}
      }
    `,
    2: css`
      padding: 0 8px;
      height: 40px;
      gap: 4px;
      span {
        ${textVariants['medium-3']}
      }
    `,
    3: css`
      padding: 0 12px;
      height: 48px;
      gap: 8px;
      span {
        ${textVariants['medium-3']}
      }
    `,
    4: css`
      padding: 0 16px;
      height: 56px;
      gap: 8px;
      span {
        ${textVariants['medium-4']}
      }
    `,
  }[size]
}

export const InputPrefixWrapper = styled.div<{
  size: InputSize
  orientation: 'left' | 'right'
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--neutral-alpha-12);
  background-color: transparent;
  cursor: pointer;
  ${(p) => getInputPrefixSizeCss(p.size)}
  ${(p) =>
    p.orientation === 'left'
      ? 'border-right'
      : 'border-left'}: 1px solid var(--neutral-5);
  i {
    color: var(--neutral-alpha-11);
  }
  &:hover {
    background-color: var(--neutral-alpha-2);
    i {
      color: var(--neutral-alpha-12);
    }
  }
`

export const InputPrefixTextWrapper = styled.div<{ size: InputSize }>`
  display: flex;
  align-items: center;
  gap: ${(p) => (p.size <= 2 ? '2px' : '4px')};
`
