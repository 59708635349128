import React, { use, useCallback } from 'react'
import noop from 'lodash/noop'
import { Avatar, Flex, Text } from '@/components/ui'
import useApi from '@/hooks/useApi'
import { useWizardContext } from '@/contexts/Wizard'
import { IUser } from '@/models/IUser'
import { FullScreenMobileContainer } from '@/components/uiJar/utilityStyles'
import { PopupTopBar } from '@/components/uiJar/popup'
import { Button } from '@/components/uiJar/Button'
import getAdvisorTitle from '@/utils/getAdvisorTitle'
import {
  PopupActions,
  PopupContent,
  PopupNotice,
  Procedures,
  ProceduresWrapper,
  TopbarWrapper,
} from './styles'
import { BaseWizardPopupProps } from '../types'
interface HireAdvisorPopupProps extends BaseWizardPopupProps {
  onConfirm: () => void
  advisor: IUser
}

export const HireAdvisorPopup = ({
  onConfirm,
  onClose,
  advisor,
}: HireAdvisorPopupProps) => {
  const { api, status } = useApi()
  const { userWizard, updateUserWizard } = useWizardContext()

  const handleHireLawyer = useCallback(() => {
    api.wizard
      .hireAdvisor(userWizard.id)
      .then((data) => {
        updateUserWizard(data)
        onConfirm()
      })
      .catch(noop)
  }, [api, userWizard.id, updateUserWizard, onConfirm])

  return (
    <FullScreenMobileContainer>
      <TopbarWrapper>
        <PopupTopBar onClose={onClose}>
          <Text variant="semibold-4">Request Proposal</Text>
        </PopupTopBar>
      </TopbarWrapper>

      <PopupContent>
        <Avatar size={40} url={advisor.avatarFull} />
        <Flex alignItems="start" direction="column" gap={4}>
          <Text variant="semibold-4">Ready to take the next step?</Text>
          <Text variant="regular-3" colorVar="neutral-11">
            {`If you've had a consultation with your ${getAdvisorTitle(
              userWizard.country?.isoCode,
            )} already and are
            ready to take the next step, you can request a proposal from your ${getAdvisorTitle(
              userWizard.country?.isoCode,
              'short',
            )}.`}
          </Text>
        </Flex>
        <ProceduresWrapper>
          <Text variant="medium-3" colorVar="neutral-11">
            How it works?
          </Text>
          <Procedures>
            <li>{`Your ${getAdvisorTitle(
              userWizard.country?.isoCode,
              'short',
            )} will receive your request for proposal.`}</li>
            <li>
              {`Your ${getAdvisorTitle(
                userWizard.country?.isoCode,
                'short',
              )} will send over their quote and detailed list of
              services they will provide.`}
            </li>
            <li>You can then accept or reject the proposal.</li>
          </Procedures>
        </ProceduresWrapper>

        <PopupNotice variant="regular-2">
          You won&apos;t be charged anything now.
        </PopupNotice>
      </PopupContent>

      <PopupActions>
        <Button
          onClick={onClose}
          fullWidth
          variant="outline"
          size={3}
          colorTheme="neutral"
        >
          Not now
        </Button>
        <Button
          fullWidth
          onClick={handleHireLawyer}
          isLoading={status === 'posting'}
          variant="solid"
          size={3}
          colorTheme="neutral"
        >
          Request Proposal
        </Button>
      </PopupActions>
    </FullScreenMobileContainer>
  )
}
