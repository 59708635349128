import React, { Fragment, useMemo, useState } from 'react'
import { Text, Flex } from '@/components/ui'
import { PopupTopBar } from '@/components/uiJar/popup'
import {
  DashedDivider,
  Divider,
  MobileOnlyContainer,
} from '@/components/uiJar/utilityStyles'
import { Button } from '@/components/uiJar/Button'
import InputField from '@/components/uiJar/InputField'
import { ConfirmProposalPaymentProps } from './types'
import {
  PopupContainer,
  PopupContent,
  PopupGrid,
  TotalsContainer,
  Tab,
  PopupGridWrapper,
  RequestPaymentContainer,
  PopupFooter,
  PaymentBreakdownContainer,
} from './styles'

enum PopupTab {
  requestPayment = 0,
  paymentBreakdown = 1,
}

export const ConfirmProposalPayment = ({
  isLoading,
  initialAmount,
  proposal,
  platformFee = 30,
  onSendProposal,
  onClose,
  onBack,
  mode,
  editing,
}: ConfirmProposalPaymentProps) => {
  const [amount, setAmount] = useState<number | undefined>(initialAmount)
  const [activeTab, setActiveTab] = useState<PopupTab>(PopupTab.requestPayment)

  const [
    proposedTotal,
    techFee,
    earning,
    dueToday,
    InitialTechFee,
    InitialEarning,
    remainingDue,
  ] = useMemo(() => {
    const initialRequestAmount = amount ? amount : 0

    const prices = proposal?.prices
    const proposedTotal =
      prices?.map((item) => item.price).reduce((a, b) => a + b, 0) || 0

    const crFee = (proposedTotal * platformFee) / 100
    const totalEarning = proposedTotal - crFee

    const initialTechFee = (initialRequestAmount * platformFee) / 100
    const initialEarning = initialRequestAmount - initialTechFee

    const RemainingDue = proposedTotal - initialRequestAmount

    return [
      Math.ceil(proposedTotal).toLocaleString(),
      Math.ceil(crFee).toLocaleString(),
      Math.ceil(totalEarning).toLocaleString(),
      Math.ceil(initialRequestAmount).toLocaleString(),
      Math.ceil(initialTechFee).toLocaleString(),
      Math.ceil(initialEarning).toLocaleString(),
      Math.ceil(RemainingDue).toLocaleString(),
    ]
  }, [amount, platformFee, proposal?.prices])

  const handleCreateProposal = () => {
    const body = {
      ...proposal,
    }
    if (amount) {
      body.initialPayment = amount
    }
    onSendProposal(body)
  }

  return (
    <PopupContainer>
      <PopupTopBar className="custom-topbar" onClose={onClose}>
        {mode === 'request' ? 'Request Payment' : 'Payment review'}
      </PopupTopBar>
      <MobileOnlyContainer>
        <Flex gap={0}>
          <Tab
            onClick={() => setActiveTab(PopupTab.requestPayment)}
            selected={activeTab === PopupTab.requestPayment}
          >
            Request payment
          </Tab>
          <Tab
            onClick={() => setActiveTab(PopupTab.paymentBreakdown)}
            selected={activeTab === PopupTab.paymentBreakdown}
          >
            Payment breakdown
          </Tab>
        </Flex>
      </MobileOnlyContainer>
      <PopupGridWrapper>
        <PopupGrid>
          <RequestPaymentContainer
            selectedTab={activeTab === PopupTab.requestPayment}
          >
            <PopupContent>
              <Flex direction="column" gap={20}>
                <Flex direction="column" gap={16}>
                  <Flex justifyContent="space-between">
                    <Text variant="medium-2" colorVar="neutral-9">
                      Service
                    </Text>
                    <Text variant="medium-2" colorVar="neutral-9">
                      Price
                    </Text>
                  </Flex>
                  {proposal?.prices?.map((item, index) => (
                    <Fragment key={index}>
                      <Flex justifyContent="space-between">
                        <Text variant="medium-3">{item.title}</Text>
                        <Text variant="medium-3">
                          ${item.price.toLocaleString()}
                        </Text>
                      </Flex>
                      <DashedDivider />
                    </Fragment>
                  ))}
                  <Flex justifyContent="space-between">
                    <Text variant="semibold-3">Proposed Total</Text>
                    <Text variant="semibold-3">${proposedTotal}</Text>
                  </Flex>
                </Flex>
                {mode === 'request' && (
                  <Fragment>
                    <Divider />
                    <InputField
                      label="Initial Payment Request"
                      placeholder="Enter amount in USD"
                      size={2}
                      variant="outline"
                      min={1}
                      max={Number(proposedTotal)}
                      type="number"
                      required
                      onChange={(e) =>
                        setAmount(Number.parseFloat(e.target.value))
                      }
                      value={amount}
                    />
                  </Fragment>
                )}
              </Flex>
            </PopupContent>
            <PopupFooter>
              <Button
                colorTheme="neutral"
                type="button"
                variant="outline"
                size={3}
                onClick={onBack}
                fullWidth
              >
                Back to editing
              </Button>
              <Button
                colorTheme="neutral"
                variant="solid"
                size={3}
                isLoading={isLoading}
                disabled={(!amount && mode === 'request') || isLoading}
                onClick={handleCreateProposal}
                fullWidth
              >
                {editing ? 'Update proposal' : 'Send proposal'}
              </Button>
            </PopupFooter>
          </RequestPaymentContainer>
          <PaymentBreakdownContainer
            selectedTab={activeTab === PopupTab.paymentBreakdown}
          >
            <PopupContent>
              <Flex gap={32} direction="column">
                <Flex gap={8} direction="column">
                  <Text variant="medium-2" colorVar="neutral-9">
                    Proposal Total
                  </Text>
                  <TotalsContainer direction="column">
                    <Flex justifyContent="space-between">
                      <Text variant="medium-3">Proposal total</Text>
                      <Text variant="medium-3">${proposedTotal}</Text>
                    </Flex>
                    <DashedDivider />
                    <Flex justifyContent="space-between">
                      <Text variant="medium-3">
                        Total Technology Fee {platformFee}%
                      </Text>
                      <Text variant="medium-3">${techFee}</Text>
                    </Flex>
                    <DashedDivider />
                    <Flex justifyContent="space-between">
                      <Text variant="semibold-3">Your Total Earning</Text>
                      <Text variant="semibold-3">${earning}</Text>
                    </Flex>
                  </TotalsContainer>
                </Flex>
                <Flex gap={8} direction="column">
                  <Text variant="medium-2" colorVar="neutral-9">
                    Initial Payment Request
                  </Text>
                  <TotalsContainer direction="column">
                    <Flex justifyContent="space-between">
                      <Text variant="medium-2">Due today from client</Text>
                      <Text variant="medium-2">${dueToday}</Text>
                    </Flex>
                    <DashedDivider />
                    <Flex justifyContent="space-between">
                      <Text variant="medium-2">
                        Total Technology Fee {platformFee}%
                      </Text>
                      <Text variant="medium-2">${InitialTechFee}</Text>
                    </Flex>
                    <DashedDivider />
                    <Flex justifyContent="space-between">
                      <Text variant="semibold-3">
                        Your Earning for Initial Payment
                      </Text>
                      <Text variant="semibold-3">${InitialEarning}</Text>
                    </Flex>
                  </TotalsContainer>
                </Flex>
                <TotalsContainer>
                  <Flex justifyContent="space-between" className="full-width">
                    <Flex direction="column" gap={2}>
                      <Text variant="medium-3">Remaining due from client</Text>
                      <Text variant="medium-2" colorVar="neutral-11">
                        after initial payment
                      </Text>
                    </Flex>
                    <Text variant="medium-3">${remainingDue}</Text>
                  </Flex>
                </TotalsContainer>
              </Flex>
            </PopupContent>
            <PopupFooter>
              <Text variant="regular-2" colorVar="neutral-9">
                We’re applying {platformFee}% Technology Fee to all payments in
                order to maintain work of Citizen Remote’s platform.
              </Text>
            </PopupFooter>
          </PaymentBreakdownContainer>
        </PopupGrid>
      </PopupGridWrapper>
    </PopupContainer>
  )
}
